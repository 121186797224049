/* eslint-disable no-unused-vars */
import React from "react";
// eslint-disable-next-line no-unused-vars
import styles from "./faultFrequencyDetectionTable.module.scss";
import {useChartActions, useSelectChartPointsForDetection, useSelectChartSelectedPoint, useSelectChartType} from "../../store/hooks/useStoreContext";
import {useChartRef} from "../../hooks/useChartRef";
import {useSpeedSettings} from "../../hooks/helpers/useSpeedSettings";
import FrequencyConverter from "../../../../../../helpers/frequency-converter";
import {useSeriesList} from "../../../../../../hooks/helpers/useSeriesList";
import ChartHelper from "../../../../../../helpers/chart";
import Helper from "../../../../../../helpers/helper";
import {get as _get, sortedLastIndex as _sortedLastIndex, uniq as _uniq} from "lodash";
import useAxisLabelQuery from "../../../../../../hooks/api/axisLabel/useAxisLabelQuery";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {BEARING_TYPES} from "../../../../../../constants/constants";
import {useInstallPointFaultFrequency} from "../../../../../../hooks/api/faultFrequency/useFaultFrequencyQuery";
import {useUserFftChartFrequency} from "../../../../../../stores/UserLocalSettingsStore";

const tableCells = [
    {label: "Identifier", field: "identifier"},
    {label: "Frequency", field: "frequency"},
    {label: "Difference", field: "difference"},
    {label: "Order", field: "order"},
    {label: "Fault Frequency", field: "faultFrequency"},
    {label: "Bearings", field: "bearings"},
    {label: "Value", field: "axis"},
    {label: "", isAction: true},
];

const AXIS_IDS = {
    X: "1",
    Y: "2",
    Z: "3",
};

const axisLabelMap = {
    1: "X-Axis",
    2: "Y-Axis",
    3: "Z-Axis",
};

const ORDER_FILTER_VALUE = 0.1;

export const FaultFrequencyDetectionTable = () => {
    const pointsForDetection = useSelectChartPointsForDetection();

    const chartRef = useChartRef();
    const {currentSpeed} = useSpeedSettings();
    const series = chartRef?.current?.chart?.series;

    const {setPointForDetection} = useChartActions();
    const chartType = useSelectChartType();
    const {precision, units} = useSeriesList(chartType);
    const {equipmentItem} = useEquipmentByParams();
    const selectedPoint = useSelectChartSelectedPoint();
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(equipmentItem.id);
    const {filteredFaultFrequencies, isLoading} = useInstallPointFaultFrequency(equipmentItem.id, selectedPoint);
    const currentFrequency = useUserFftChartFrequency();

    const bearings = _get(
        equipmentItem.installationPoints.filter((installationPoint) => installationPoint.id === +selectedPoint),
        "0.bearings",
        []
    );

    const calculateFaultFrequency = (detectedItem) => {
        const axis = calculateFrequency(detectedItem);
        const perAxis = axis.map(([axisId, value, readingOptions]) => {
            const currentFrequencyValue = currentSpeed ? FrequencyConverter.fromHz(value, currentSpeed).toOrders().value : value;
            const pickedBearings = [];
            const tickFilterValue = currentSpeed ? ORDER_FILTER_VALUE : (2 * readingOptions.fmax) / readingOptions.lor;

            filteredFaultFrequencies.forEach((item) => {
                for (let i = 1; i <= item.plot_lines_count; i++) {
                    const orderPerTick =
                        (currentSpeed
                            ? FrequencyConverter.fromType(item.frequency_type, item.frequency, currentSpeed).toOrders().value
                            : FrequencyConverter.fromType(item.frequency_type, item.frequency, currentSpeed).toHz().value) * i;

                    if (orderPerTick <= currentFrequencyValue + tickFilterValue && orderPerTick >= currentFrequencyValue - tickFilterValue) {
                        pickedBearings.push({faultName: item.name, tickIndex: i});
                    }
                }
            });

            const labels = pickedBearings.map((item) => `${item.tickIndex}x ${item.faultName}`);
            return [axisId, _uniq(labels).join(", ") || "--"];
        });

        return perAxis;
    };

    const calculateBearings = (chartX) => {
        const axis = calculateFrequency(chartX);
        const perAxis = axis.map(([axisId, value]) => {
            if (!currentSpeed) {
                return [axisId, "--"];
            }

            const order = FrequencyConverter.fromHz(value, currentSpeed).toOrders().value;
            const pickedBearings = [];

            bearings.forEach((item) => {
                BEARING_TYPES.forEach((bearingName) => {
                    for (let i = 1; i <= item.plot_lines_count; i++) {
                        const orderPerTick = item[bearingName] * i;

                        if (orderPerTick <= order + ORDER_FILTER_VALUE && orderPerTick >= order - ORDER_FILTER_VALUE) {
                            pickedBearings.push({bearingName, tickIndex: i});
                        }
                    }
                });
            });

            const labels = pickedBearings.map((item) => `${item.tickIndex}x ${item.bearingName}`);
            return [axisId, _uniq(labels).join(", ") || "--"];
        });

        return perAxis;
    };

    const calculateAxisUnitValue = (values) => {
        return values.map(([axisId, value, __, pointIndex]) => {
            const series = chartRef?.current?.chart.series.find((item) => item.userOptions.axisId === axisId);

            if (value > series.xData.lastItem) {
                return [axisId, "--"];
            }

            if (value === undefined) {
                return [axisId, "--"];
            }

            if (!series) {
                return [axisId, "--"];
            }

            const [, y] = series.options.data[pointIndex] || [];
            const converted = Helper.numberFormat(y, precision);

            return [axisId, `${converted} ${units}`];
        });
    };

    const calculateFrequency = (detectedItem, withFixed = false) => {
        const valuePerxis = series
            ?.filter((item) => !!item.userOptions.axisId)
            .map((item) => {
                const xValue = detectedItem.x;
                const index = ChartHelper.getNearestPointIndex(item, xValue, true, true);
                const [value] = item.userOptions.data[index] || [];
                const [prevValue] = item.userOptions.data[index - 1] || [];

                const diff = value - prevValue;
                const diffForClick = value - xValue;

                if (xValue > item.xData.lastItem) {
                    return [item?.userOptions.axisId, undefined, item?.userOptions, index];
                }

                if (diff / 2 < diffForClick) {
                    const [valueNext] = item.userOptions.data[index - 1] || [];

                    return [item?.userOptions.axisId, withFixed ? valueNext.toFixed(2) : valueNext, item?.userOptions, index];
                }

                return [item?.userOptions.axisId, withFixed ? value.toFixed(2) : value, item?.userOptions, index];
            });

        return valuePerxis;
    };

    const convertFrequencyToUnits = (values) => {
        return values.map(([axisId, value, ...rest]) => {
            if (!value || isNaN(value)) {
                return [axisId, "--"];
            }

            return [
                axisId,

                currentFrequency === "CPM"
                    ? parseFloat(FrequencyConverter.fromHz(value, currentSpeed).toType(currentFrequency).numberFormat(precision)).toFixed(2) + "CPM"
                    : value + "Hz",
                ...rest,
            ];
        });
    };

    const calculateDifference = (detectedItem, detectedItemPrev) => {
        const values = calculateFrequency(detectedItem);
        const prevValues = calculateFrequency(detectedItemPrev);

        return values.map(([axisId, value], index) => {
            const diff = value - prevValues[index][1];
            return [axisId, (diff < 0 ? diff * -1 : diff).toFixed(2)];
        });
    };

    const calculateOrders = (chartX) => {
        const values = calculateFrequency(chartX);

        return values.map(([axisId, value]) => [axisId, FrequencyConverter.fromHz(value, currentSpeed).toOrders().numberFormat()]);
    };

    const formatValuesWithAxis = (values) =>
        values?.map?.(([axisId, value]) => `${axisLabels?.[axisId]?.[selectedPoint] || axisLabelMap[axisId]} ${value}`).join("\n") || "--";

    const tableRows = pointsForDetection.slice().map((item, index, arr) => ({
        ...item,
        identifier: `C${index + 1}`,
        frequency: formatValuesWithAxis(convertFrequencyToUnits(calculateFrequency(item, true))),
        difference: arr[index - 1] ? formatValuesWithAxis(convertFrequencyToUnits(calculateDifference(item, arr[index - 1]))) : "--",
        order: currentSpeed ? formatValuesWithAxis(calculateOrders(item)) : "--",
        axis: formatValuesWithAxis(calculateAxisUnitValue(calculateFrequency(item, true))),
        bearings: formatValuesWithAxis(calculateBearings(item)),
        faultFrequency: formatValuesWithAxis(calculateFaultFrequency(item)),
    }));

    const handleDeleteAll = (e) => {
        setPointForDetection([]);
        e.target.blur();
    };

    const handleDeleteById = (id) => {
        const filtered = pointsForDetection.filter((item) => item.id !== id);
        setPointForDetection(filtered);
    };

    return (
        <div className={styles.table}>
            <table className="table table-hover tl-fixed">
                <thead>
                    <tr>
                        {tableCells.map((item) => (
                            <th key={item.field}>
                                {item.isAction ? (
                                    <button
                                        onClick={handleDeleteAll}
                                        className="btn btn-primary btn-sm"
                                    >
                                        Clear All
                                    </button>
                                ) : (
                                    item.label
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className={styles.tableBody}>
                    {tableRows.map((item) => (
                        <tr key={item.id}>
                            {tableCells.map((cell) => (
                                <td
                                    key={cell.field}
                                    style={{whiteSpace: "pre-line"}}
                                >
                                    {cell.isAction ? (
                                        <button
                                            onClick={() => handleDeleteById(item.id)}
                                            className="btn btn-primary btn-sm"
                                        >
                                            Remove
                                        </button>
                                    ) : (
                                        item[cell.field]
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
