import {useChartStateStoreContext, useSelectChartIsFaultFrequencyDetectionMode} from "../store/hooks/useStoreContext";
import {useEffect, useMemo} from "react";
import {useBearingBuilder} from "./factory/useBearingBuilder";
import {useFaultFrequencyBuilder} from "./factory/useFaultFrequencyBuilder";
import {useSeriesBuilder} from "./factory/useSeriesBuilder";
import {useChartMarksBuilder} from "./factory/useChartMarksBuilder";
import {useRpmBuilder} from "./factory/useRpmBuilder";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
import {useCommonChartOptionsBuilder} from "./factory/useCommonChartOptionsBuilder";
import {useBandAlertBuilder} from "./factory/useBandAlertBuilder";
import {useEnvelopeBuilder} from "./factory/useEnvelopeBuilder";
import {useChartScaleInit} from "./useChartScaleInit";
import {useChartRef} from "./useChartRef";
import {useOverlaySeriesBuilder} from "./factory/useOverlaySeriesBuilder";
import {useCurrentDate} from "./helpers/useCurrentDate";
import {useActiveAlertAnnotation} from "./factory/useActiveAlertAnnotation";
import auth from "../../../../../services/auth";
import $ from "jquery";
import Helper from "../../../../../helpers/helper";
import {useDeleteChartMarkFftModal} from "../../../../../modals/chartMarkFft/hooks/useDeleteChartMarkFftModal";
import {useChartMarkFftModal} from "../../../../../modals/chartMarkFft/hooks/useChartMarkFftModal";
import {useHistory} from "react-router";
import {useFaultFrequencyDetectionPlotLine} from "./factory/useFaultFrequencyDetectionPlotLine";

export const useDrawChartEffect = () => {
    const chartRef = useChartRef();
    const options = useCommonChartOptionsBuilder();

    const {equipmentItem} = useEquipmentByParams();
    const {setLoader, setChartMarkEditId, setChartMarkDeleteId, setNoData} = useChartStateStoreContext((state) => state.actions);

    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const chartViewType = useChartStateStoreContext((state) => state.settings.chartViewType);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const showAlerts = useChartStateStoreContext((state) => state.settings.showAlerts);
    const isFaultFrequencyDetectionMode = useSelectChartIsFaultFrequencyDetectionMode();

    const currentDate = useCurrentDate();

    const {series, readingsLoaded, isFetched, isLoading, isRefetching, isTimestampsRefetching} = useSeriesBuilder(equipmentItem);

    const bearings = useBearingBuilder(equipmentItem, series);

    const {xAxisPlotLines: xAxisFaultFrequencyDetectionPlotLine} = useFaultFrequencyDetectionPlotLine();

    const activeAlertAnnotation = useActiveAlertAnnotation();

    const faultFrequencies = useFaultFrequencyBuilder(equipmentItem);

    const chartMarks = useChartMarksBuilder(equipmentItem);

    const rpmPlotLines = useRpmBuilder();

    const bandAlerts = useBandAlertBuilder();

    const {envelopeAlerts, envelopeSeries} = useEnvelopeBuilder();

    const {series: overlaySeries, readingsLoaded: overlayReadingsLoaded, isLoading: isOverlayLoading} = useOverlaySeriesBuilder();

    useChartScaleInit(chartRef, [series, bandAlerts, envelopeAlerts, envelopeSeries, activeAlertAnnotation]);

    useEffect(() => {
        if (isLoading || isOverlayLoading || isRefetching) {
            setLoader(true);
        }
    }, [isLoading, isOverlayLoading, isRefetching]);

    useEffect(() => {
        readingsLoaded === true && isFetched && overlayReadingsLoaded && !isRefetching && !isTimestampsRefetching && setLoader(false);
    }, [readingsLoaded, currentDate, chartMode, chartViewType, seriesList, overlaySeries, isRefetching, isTimestampsRefetching]);

    // TODO This is temp solution.
    const chartWrapperSelector = `.chart-block[data-chart-type=${chartType}]`;
    const [showModal] = useChartMarkFftModal();
    const [showDeleteChartMarkSimpleModal] = useDeleteChartMarkFftModal();
    const history = useHistory();

    useEffect(() => {
        if (auth.userCan("editAlarms")) {
            $(document).on("click", `${chartWrapperSelector} .highcharts-alert-action`, function () {
                history.push(Helper.setHashParams({modalAlarm: $(this).data("type"), id: $(this).data("id")}));
            });
            $(document).on("click", `${chartWrapperSelector} .alert-badge-${chartType}`, function () {
                history.push(
                    Helper.setHashParams({
                        modal: "alert",
                        type: chartType,
                        axisId: $(this).data("axisId"),
                        installationPointId: $(this).data("installationPointId"),
                        id: $(this).data("alertId"),
                    })
                );
            });
        }
        $(document).on("mouseover", `${chartWrapperSelector} .chart-alert-wrapper`, function () {
            let elem = $(this).find(".chart-alert-marker");
            if (elem.length && chartRef.current) {
                let elemLabel = elem.find(".chart-label");
                elemLabel.removeClass("invisible");

                let width = elemLabel.outerWidth() + 10;
                let clickPos = elem.offset().left - $(chartRef.current.chart.renderTo).offset().left;
                let chartWidth = chartRef.current.chart.chartWidth;

                if (chartWidth - clickPos < width) {
                    elemLabel.removeClass("open-left");
                    elemLabel.addClass("open-right");
                } else {
                    elemLabel.removeClass("open-right");
                    elemLabel.addClass("open-left");
                }
            }
        });
        $(document).on("mouseout", `${chartWrapperSelector} .chart-alert-wrapper`, function (event) {
            if (event.offsetX < 0 || event.offsetY < 0 || event.offsetX > 10 || event.offsetX > 10) $(this).find(".chart-label").addClass("invisible");
        });
        $(document).on("click", `${chartWrapperSelector} .chart-mark-delete`, (event) => {
            setChartMarkDeleteId($(event.target).data("id"));
            showDeleteChartMarkSimpleModal();
        });
        $(document).on("click", `${chartWrapperSelector} .chart-mark-edit`, (event) => {
            setChartMarkEditId($(event.target).data("id"));
            showModal();
        });
        $(document).on("mouseover", `${chartWrapperSelector} .chart-mark-wrapper`, function () {
            let elem = $(this).find(".chart-mark-block");
            if (elem.length && chartRef.current) {
                let elemLabel = elem.find(".chart-mark-label");
                let width = elemLabel.outerWidth() + 10;
                let clickPos = elem.offset().left - $(chartRef.current.chart.renderTo).offset().left;

                $(".chart-mark-label").addClass("invisible");
                elemLabel.removeClass("invisible");

                let chartWidth = chartRef.current.chart.chartWidth;

                if (chartWidth - clickPos < width) {
                    elemLabel.removeClass("open-left");
                    elemLabel.addClass("open-right");
                } else {
                    elemLabel.removeClass("open-right");
                    elemLabel.addClass("open-left");
                }
            }
        });
        $(document).on("mouseout", `${chartWrapperSelector}  .chart-mark-wrapper`, function () {
            $(this).find(".chart-mark-label").addClass("invisible");
        });

        return () => {
            $(document).off("mouseover", `${chartWrapperSelector} .chart-mark-wrapper`);
            $(document).off("mouseout", `${chartWrapperSelector} .chart-mark-wrapper`);
            $(document).off("mouseover", `${chartWrapperSelector} .chart-alert-wrapper`);
            $(document).off("mouseout", `${chartWrapperSelector} .chart-alert-wrapper`);
            $(document).off("click", `${chartWrapperSelector} .chart-mark-delete`);
            $(document).off("click", `${chartWrapperSelector} .chart-mark-edit`);
        };
    }, [chartRef]);

    return useMemo(() => {
        if (!series.length) {
            setNoData(true);
        } else {
            setNoData(false);
        }

        let annotationsChartArray = [];
        let seriesSoftMax = 0;
        if (activeAlertAnnotation.length) {
            annotationsChartArray = [...activeAlertAnnotation];
            activeAlertAnnotation.forEach((alert) => {
                const alertValue = (alert?.labels || []).reduce((result, label) => (label.point.y > result ? label.point.y : result), 0);
                if (seriesSoftMax < alertValue) {
                    seriesSoftMax = alertValue;
                }
            });
        }

        if ((envelopeAlerts?.shapes || []).length) {
            annotationsChartArray.push(envelopeAlerts);
        }
        if ((bandAlerts?.shapes || []).length) {
            annotationsChartArray.push(bandAlerts);
        }

        let seriesChartArray = [];
        if (series.length) {
            seriesChartArray = seriesChartArray.concat(series);
        }
        if (envelopeSeries.length && series.length) {
            seriesChartArray = seriesChartArray.concat(envelopeSeries);
        }
        if (overlaySeries.length && series.length) {
            seriesChartArray = seriesChartArray.concat(overlaySeries);
        }

        let xAxisPlotLinesChartArray = [];
        if (bearings.length) {
            xAxisPlotLinesChartArray = xAxisPlotLinesChartArray.concat(bearings);
        }
        if (faultFrequencies.length) {
            xAxisPlotLinesChartArray = xAxisPlotLinesChartArray.concat(faultFrequencies);
        }
        if (chartMarks.length) {
            xAxisPlotLinesChartArray = xAxisPlotLinesChartArray.concat(chartMarks);
        }
        if (rpmPlotLines.length) {
            xAxisPlotLinesChartArray = xAxisPlotLinesChartArray.concat(rpmPlotLines);
        }
        if (xAxisFaultFrequencyDetectionPlotLine?.length) {
            xAxisPlotLinesChartArray = xAxisPlotLinesChartArray.concat(xAxisFaultFrequencyDetectionPlotLine);
        }

        return {
            ...options,
            series: seriesChartArray,
            annotations: annotationsChartArray,
            xAxis: {
                ...options.xAxis,
                plotLines: xAxisPlotLinesChartArray,
            },
            yAxis: {
                ...options.yAxis,
                softMax: showAlerts
                    ? (bandAlerts?.labels || []).reduce((result, label) => (label.point.y > result ? label.point.y : result), seriesSoftMax)
                    : seriesSoftMax,
            },
        };
    }, [
        options,
        showAlerts,
        series,
        bearings,
        faultFrequencies,
        chartMarks,
        rpmPlotLines,
        bandAlerts,
        envelopeAlerts,
        envelopeSeries,
        overlaySeries,
        activeAlertAnnotation,
        xAxisFaultFrequencyDetectionPlotLine,
        isFaultFrequencyDetectionMode,
    ]);
};
