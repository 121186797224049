import SelectFormGroup, {FileFormGroup, ImageFormGroup, InputFormGroup, TextareaFormGroup} from "../../../shared/formParts/formParts";
import React, {useState} from "react";
import {get as _get} from "lodash";
import PropTypes from "prop-types";
import UniversalAdapterParameterForm from "./parameterTypes/form";

const WuaConfigForm = ({data, formErrors, inProgress, readingTypes, valueTypes, isRtd, onChange, fetchReadingTypes, onBatchChange}) => {
    const [addParameter, setAddParameter] = useState(false);

    return (
        <div className={"wua-config-form"}>
            <div className={"row mt-2"}>
                <div className={"col-md-12"}>
                    <InputFormGroup
                        label={"Configuration Name"}
                        name={"name"}
                        value={_get(data, "name")}
                        errorMsg={_get(formErrors, "name")}
                        disabled={inProgress}
                        isRequired={true}
                        onChange={onChange}
                    />
                </div>
            </div>

            <hr className={"mt-4 mb-4"} />

            <div className={"row mt-2"}>
                <div className={"col-md-12"}>
                    <SelectFormGroup
                        label={"Input Value Type"}
                        options={valueTypes}
                        optionValue={"id"}
                        optionName={"name"}
                        value={+_get(data, "value_type_id")}
                        valueName={"value_type_id"}
                        disabled={inProgress}
                        errorMsg={_get(formErrors, "value_type_id")}
                        isRequired={true}
                        onChange={onChange}
                    />
                </div>
            </div>

            {!isRtd && (
                <div className={"row mt-2"}>
                    <div className={"col-md-6"}>
                        <InputFormGroup
                            label={"Minimum Input Value"}
                            name={"min_voltage"}
                            value={_get(data, "min_voltage")}
                            errorMsg={_get(formErrors, "min_voltage")}
                            disabled={inProgress}
                            isRequired={true}
                            onChange={onChange}
                        />
                    </div>
                    <div className={"col-md-6"}>
                        <InputFormGroup
                            label={"Maximum Input Value"}
                            name={"max_voltage"}
                            value={_get(data, "max_voltage")}
                            errorMsg={_get(formErrors, "max_voltage")}
                            disabled={inProgress}
                            isRequired={true}
                            onChange={onChange}
                        />
                    </div>
                </div>
            )}

            <hr className={"mt-4 mb-4"} />

            <div className={"row mt-2"}>
                <div className={"col-md-12"}>
                    <SelectFormGroup
                        label={"Process Variable"}
                        addToLabel={
                            <span
                                className="add-installation-point-btn ml-1"
                                title={"Add new process variable"}
                                onClick={() => setAddParameter(true)}
                            >
                                <i className="fa fa-plus-circle"></i>
                            </span>
                        }
                        options={readingTypes}
                        optionValue={"id"}
                        optionName={"name"}
                        value={+_get(data, "reading_type_id")}
                        valueName={"reading_type_id"}
                        disabled={inProgress || isRtd}
                        errorMsg={_get(formErrors, "reading_type_id")}
                        isRequired={true}
                        onChange={onChange}
                    />
                </div>
            </div>

            {!isRtd && (
                <div className={"row mt-2"}>
                    <div className={"col-md-6"}>
                        <InputFormGroup
                            label={"Process Variable Minimum Value"}
                            name={"min_sensor_value"}
                            value={_get(data, "min_sensor_value")}
                            errorMsg={_get(formErrors, "min_sensor_value")}
                            disabled={inProgress}
                            isRequired={true}
                            onChange={onChange}
                        />
                    </div>
                    <div className={"col-md-6"}>
                        <InputFormGroup
                            label={"Process Variable Maximum Value"}
                            name={"max_sensor_value"}
                            value={_get(data, "max_sensor_value")}
                            errorMsg={_get(formErrors, "max_sensor_value")}
                            disabled={inProgress}
                            isRequired={true}
                            onChange={onChange}
                        />
                    </div>
                </div>
            )}

            <hr className={"mt-4 mb-4"} />

            <div className={"row mt-2"}>
                <div className={"col-md-6"}>
                    <InputFormGroup
                        label={"Brand"}
                        name={"brand"}
                        value={_get(data, "brand")}
                        errorMsg={_get(formErrors, "brand")}
                        disabled={inProgress}
                        isRequired={false}
                        onChange={onChange}
                    />
                </div>
                <div className={"col-md-6"}>
                    <InputFormGroup
                        label={"Model"}
                        name={"model"}
                        value={_get(data, "model")}
                        errorMsg={_get(formErrors, "model")}
                        disabled={inProgress}
                        isRequired={false}
                        onChange={onChange}
                    />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-md-6"}>
                    <FileFormGroup
                        label={"Datasheet"}
                        name={"datasheet"}
                        fileName={_get(data, "datasheet_file_name")}
                        url={_get(data, "datasheet_url")}
                        errorMsg={_get(formErrors, "datasheet")}
                        disabled={inProgress}
                        onBatchChange={onBatchChange}
                    />
                </div>
                <div className={"col-md-6"}>
                    <FileFormGroup
                        label={"Wiring Diagram"}
                        name={"wiring_diagram"}
                        fileName={_get(data, "wiring_diagram_file_name")}
                        url={_get(data, "wiring_diagram_url")}
                        errorMsg={_get(formErrors, "wiring_diagram")}
                        disabled={inProgress}
                        onBatchChange={onBatchChange}
                    />
                </div>
            </div>
            <div className={"row mt-2"}>
                <div className={"col-md-12"}>
                    <TextareaFormGroup
                        label={"Wiring Instructions"}
                        name={"wiring_instructions"}
                        value={_get(data, "wiring_instructions")}
                        errorMsg={_get(formErrors, "wiring_instructions")}
                        disabled={inProgress}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className={"row mt-2"}>
                <div className={"col-md-4"}>
                    <ImageFormGroup
                        label={"Photos"}
                        name={"photos"}
                        value={_get(data, "photos")}
                        errorMsg={_get(formErrors, "photos")}
                        disabled={inProgress}
                        onChange={onChange}
                        needConfirmDelete={true}
                    />
                </div>
            </div>
            {addParameter && (
                <UniversalAdapterParameterForm
                    onCancel={() => setAddParameter(false)}
                    onSave={() => fetchReadingTypes().then(() => setAddParameter(false))}
                />
            )}
        </div>
    );
};

WuaConfigForm.propTypes = {
    data: PropTypes.object,
    readingTypes: PropTypes.array,
    valueTypes: PropTypes.array,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    inProgress: PropTypes.bool,
    fetchReadingTypes: PropTypes.func,
    onBatchChange: PropTypes.func,
    isRtd: PropTypes.bool,
};

export default WuaConfigForm;
