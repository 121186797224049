import {createStore} from "zustand";
import {CHART_TYPE, useChartAbstractFactory} from "../hooks/useChartAbstractFactory";
import {CHART_VIEW_TYPE} from "../../../../../constants/constants";
import {useEffect, useMemo, useRef} from "react";
import {get as _get, reject as _reject, findIndex as _findIndex, find as _find} from "lodash";
import {useSearchParams} from "../hooks/chartHooks";
import {immer} from "zustand/middleware/immer";
import {getSeriesList} from "../../helpers/getSeriesList";
import {useChartTypesStore} from "../../../../../stores/zustand/ChartSelectedChartTypeStore";
import {WATERFALL_ORDER_VARIANTS} from "../components/buttons/WaterfallOrderDropdown";
import moment from "moment/moment";
import Helper from "../../../../../helpers/helper";
import {MTIME_THREE_MONTHS} from "../../../../../stores/zustand/ChartSelectedRangeStore";

export const useCreateChartStateStore = (props) => {
    const factoryResult = useChartAbstractFactory(props);
    const {chartTypeName, chartModeList} = factoryResult;
    const chartTypes = useChartTypesStore();
    const seriesListMemo = useMemo(() => {
        let seriesList = getSeriesList(props.chartType, false, chartTypes);

        const currentSelected = JSON.parse(localStorage.getItem("selectedSeries")) || {};
        const selectedForCurrentChartType = _get(currentSelected, +props.chartType, []);

        if (selectedForCurrentChartType.length) {
            seriesList.selected = selectedForCurrentChartType;
        }

        return seriesList;
    }, [props.chartType]);

    const searchParams = useSearchParams();
    const store = useRef(null);
    useEffect(
        () => () => {
            if (store.current) {
                store.current.destroy();
            }
        },
        []
    );
    if (store.current) {
        return store.current;
    }
    store.current = createStore(
        immer((set, get) => ({
            factory: factoryResult,
            isLoading: true,
            settings: {
                countHarmonicPoints: chartTypeName === CHART_TYPE.FFT ? 10 : null,
                unixFrom: Helper.findCommence(MTIME_THREE_MONTHS),
                unixTo: moment().utc().unix(),
                activeBearings: [],
                chartType: props.chartType,
                fftCursor: "normal",
                isFaultFrequencyDetectionMode: false,
                pointsForDetection: [],
                currentPoint: {},
                selectedPoint: props.selectedPoint,
                seriesList: seriesListMemo,
                currentWaterfallDates: [],
                chartMarksFft: [],
                editChartMarkFftId: null,
                deleteChartMarkFftId: null,
                isNoData: false,
                showAlerts: false,
                zoomed: false,
                fullScreenMode: false,
                showRpmPlotLines: false,
                isGridView: false,
                chartMode: Object.values(chartModeList)[0],
                chartViewType: CHART_VIEW_TYPE.LINEAR,
                show3dTrend: false,
                trend3dFrequency: "0", // required to save frequency when switching to fullscreen
                show3dFrom: "",
                show3dTo: "",
                delayedInputValues: {
                    show3dFrom: "",
                    show3dTo: "",
                },
                dropdownSeriesOpen: false,
                valueMax: "",
                valueMin: "",
                valueInitMax: "",
                valueInitMin: "",
                disabled: "disabled",
                editFaultFrequencyId: null,
                selectedFaultFrequencies: [],
                isShowZoneOnWaterfall: false,
                waterfallSort: WATERFALL_ORDER_VARIANTS.ASC,
            },
            modal: {
                bearing: searchParams.modal === "bearing",
                help: false,
                deleteReading: false,
                deleteReadingFetch: false,
                peaks: false,
            },
            speedModal: {
                show: false,
                type: "",
                isCurrent: false,
            },
            actions: {
                toggleGridView() {
                    set((state) => {
                        state.settings.isGridView = !state.settings.isGridView;
                    });
                },
                setLoader: (isLoading) => {
                    set((state) => {
                        state.isLoading = isLoading;
                    });
                },
                setEditFaultFrequencyId: (editFaultFrequencyId) => {
                    set((state) => {
                        state.settings.editFaultFrequencyId = editFaultFrequencyId;
                    });
                },
                appendPointForDetection: (point) =>
                    set((state) => {
                        state.settings.pointsForDetection.push(point);
                    }),
                setPointForDetection: (points) =>
                    set((state) => {
                        state.settings.pointsForDetection = points;
                    }),
                setIsFaultFrequencyDetectionMode: (isMode) =>
                    set((state) => {
                        state.settings.isFaultFrequencyDetectionMode = isMode;
                    }),
                setNoData: (isNoData) => {
                    set((state) => {
                        state.settings.isNoData = isNoData;
                    });
                },
                toggleShowZone() {
                    set((state) => {
                        state.settings.isShowZoneOnWaterfall = !state.settings.isShowZoneOnWaterfall;
                    });
                },
                toggleFaultFrequency: (faultFrequency) => {
                    let selectedFaultFrequencies = [...get().settings.selectedFaultFrequencies];
                    if (selectedFaultFrequencies.indexOf(faultFrequency.id) >= 0) {
                        selectedFaultFrequencies = _reject(selectedFaultFrequencies, (id) => id === faultFrequency.id);
                    } else {
                        selectedFaultFrequencies.push(faultFrequency.id);
                    }
                    set((state) => {
                        state.settings.selectedFaultFrequencies = selectedFaultFrequencies;
                    });
                },
                setZoomed: (zoomed) => {
                    set((state) => ({...state, settings: {...get().settings, zoomed}}));
                },
                updateShow3d: ({show3dFrom, show3dTo}) => {
                    set((state) => {
                        if (show3dFrom) {
                            state.settings.show3dFrom = show3dFrom;
                        }
                        if (show3dTo) {
                            state.settings.show3dTo = show3dTo;
                        }
                    });
                },
                setChartMarkDeleteId: (id) => {
                    set((state) => {
                        state.settings.deleteChartMarkFftId = id;
                    });
                },
                setChartMarkEditId: (id) => {
                    set((state) => {
                        state.settings.editChartMarkFftId = id;
                    });
                },
                setIsCurrentSpeedModal: (isCurrent) => {
                    set((state) => {
                        state.speedModal.isCurrent = isCurrent;
                    });
                },
                setCountHarmonicPoints: (count) => {
                    set((state) => {
                        state.settings.countHarmonicPoints = count;
                    });
                },
                setSpeedModal: ({type, show}) => {
                    set((state) => {
                        state.speedModal = {type, show};
                    });
                },
                toggle3dTrend: () => {
                    set((state) => {
                        state.settings.show3dTrend = !get().settings.show3dTrend;
                    });
                },
                setInitMinMax: ({valueInitMin, valueInitMax}) => {
                    if (get().settings.valueInitMin === "" || get().settings.valueInitMax === "") {
                        set((state) => ({...state, settings: {...state.settings, valueInitMax, valueInitMin}}));
                    }
                },
                setMinMax: ({valueMin, valueMax}) => {
                    set((state) => ({...state, settings: {...state.settings, valueMax, valueMin}}));
                },
                changeSelectedSeriesList: (selected) => {
                    if (!Array.isArray(selected)) {
                        selected = [selected];
                    }

                    set((state) => {
                        state.settings.seriesList.selected = selected;
                        state.isLoading = true;
                    });
                },
                setChartViewType: (chartViewType) => {
                    set((state) => {
                        state.settings.chartViewType = chartViewType;
                        state.isLoading = true;
                    });
                },
                changeChartMode: (chartMode) => {
                    set((state) => {
                        state.settings.chartMode = chartMode;
                        state.isLoading = true;
                    });
                },
                setChartCursor: (fftCursor) => {
                    set((state) => {
                        state.settings.fftCursor = fftCursor;
                    });
                },
                toggleShowRpmPlotLines: () => {
                    set((state) => {
                        state.settings.showRpmPlotLines = !get().settings.showRpmPlotLines;
                    });
                },
                setTrend3dFrequency: (trend3dFrequency) => {
                    set((state) => {
                        state.settings.trend3dFrequency = trend3dFrequency;
                    });
                },
                toggleFullScreen: () => {
                    set((state) => {
                        state.settings.fullScreenMode = !state.settings.fullScreenMode;
                    });
                },
                showAlerts() {
                    set((state) => {
                        state.settings.showAlerts = true;
                    });
                },
                toggleAlerts: () => {
                    set((state) => {
                        state.settings.showAlerts = !state.settings.showAlerts;
                    });
                },
                setCurrentWaterfallDates: (currentWaterfallDates) => {
                    set((state) => {
                        state.settings.currentWaterfallDates = currentWaterfallDates;
                        state.isLoading = true;
                    });
                },
                toggleBearing: (bearingId) => {
                    set((state) => {
                        if (_find(get().settings.activeBearings, bearingId)) {
                            const index = _findIndex(get().settings.activeBearings, bearingId);
                            state.settings.activeBearings.splice(index, 1);
                        } else {
                            state.settings.activeBearings.push(bearingId);
                        }
                    });
                },
                setWaterfallSort: (waterfallSort) => {
                    set((state) => {
                        state.settings.waterfallSort = waterfallSort;
                    });
                },
                setUnix: ({unixFrom, unixTo}) => {
                    set((state) => {
                        state.settings.unixFrom = unixFrom;
                        state.settings.unixTo = unixTo;
                    });
                },
            },
        }))
    );

    return store.current;
};
