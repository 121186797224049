import React from "react";
import {useChartActions, useSelectChartIsFaultFrequencyDetectionMode} from "../../../store/hooks/useStoreContext";

export const FaultFrequencyDetectionButton = () => {
    const {toggleFullScreen, setIsFaultFrequencyDetectionMode, setPointForDetection} = useChartActions();
    const isFaultFrequencyDetectionMode = useSelectChartIsFaultFrequencyDetectionMode();

    const handleFullScreen = () => {
        toggleFullScreen();
        setIsFaultFrequencyDetectionMode(!isFaultFrequencyDetectionMode);
        setPointForDetection([]);
    };

    return (
        <div className="btn-group-sm">
            <button
                onClick={handleFullScreen}
                className="btn btn-default"
            >
                FF Label
            </button>
        </div>
    );
};
