/* eslint-disable no-unused-vars */
import {useQuery} from "@tanstack/react-query";
import moment from "moment";
import Helper from "../../../helpers/helper";
import {FFT_TIMESTAMPS_API} from "./constants";
import ApiReading from "../../../api/reading";
import {get as _get} from "lodash";
import {MTIME_TWO_MONTH} from "../../../stores/zustand/ChartSelectedRangeStore";

const IMPACT_VUE_KEY = "fftTimestampsImpactVue";
const REGULAR = "fftTimestamps";

const parseApiResult = (timestamps) => {
    if (Array.isArray(timestamps)) {
        timestamps = {};
    }

    return {
        [REGULAR]: !timestamps.timestamps || Array.isArray(timestamps.timestamps) ? {} : timestamps.timestamps,
        [IMPACT_VUE_KEY]: !timestamps.timestampsHFDvue || Array.isArray(timestamps.timestampsHFDvue) ? {} : timestamps.timestampsHFDvue,
    };
};

const getTimestampsKey = (isImpactVue = false) => (isImpactVue ? IMPACT_VUE_KEY : REGULAR);

export const pointSelector = (data, isImpactVue, pointID) => {
    const timestampsKey = getTimestampsKey(isImpactVue);
    return _get(data, [timestampsKey, pointID], {});
};

export const axisSelector = (data, isImpactVue, pointID, axisID) => {
    if (+axisID === 0) {
        const timestampsKey = getTimestampsKey(isImpactVue);
        return _get(data, [timestampsKey, pointID], {});
    }
    const timestampsKey = getTimestampsKey(isImpactVue);
    return _get(data, [timestampsKey, pointID, axisID], {});
};

export const dateListByPointSelector = (data, isImpactVue, pointID, axes) => {
    const timestampsKey = getTimestampsKey(isImpactVue);
    return Object.keys(_get(data, [timestampsKey, pointID, axes.at(0)], []));
};

export const timeListByPointDateSelector = (data, isImpactVue, pointID, date, axes) => {
    const timestampsKey = getTimestampsKey(isImpactVue);
    const dateIndex = date === "last" ? Object.keys(_get(data, [timestampsKey, pointID, axes.at(0)], [])).at(-1) : date;

    const time = _get(data, [timestampsKey, +pointID, +axes.at(-1), dateIndex], []);
    const timeEntries = Object.entries(time).map(([time, value]) => [
        time,
        {
            ...value,
            availableAxisTimestamps: {
                1: _get(data, [timestampsKey, +pointID, 1, dateIndex, time, "id"]),
                2: _get(data, [timestampsKey, +pointID, 2, dateIndex, time, "id"]),
                3: _get(data, [timestampsKey, +pointID, 3, dateIndex, time, "id"]),
            },
        },
    ]);
    return dateIndex ? Object.fromEntries(timeEntries) : [];
};

export const timeObjectByPointDateSelector = (data, isImpactVue, pointID, dateObject, axes) => {
    const availableTimesList = timeListByPointDateSelector(data, isImpactVue, pointID, dateObject.date, axes);
    return _get(availableTimesList, [dateObject.time], {});
};

export const lastTenByAxis = (data, isImpactVue, pointID, axisID) => {
    const timestampsKey = getTimestampsKey(isImpactVue);
    const lastTen = [];

    const timestamps = _get(data, [timestampsKey, pointID, axisID], []);
    //TODO rework - bad complexity with breaker point;
    const datesArr = Object.keys(timestamps).reverse();
    for (let date of datesArr) {
        const timesArr = Object.keys(_get(data, [timestampsKey, pointID, axisID, date], [])).reverse();
        const timesByAxis = _get(data, [timestampsKey, pointID], []);

        for (let time of timesArr) {
            const readingId = timestamps[date][time].id;
            lastTen.push({
                date: date,
                time: time,
                readingId,
                axisID,
                availableAxisTimestamps: {
                    1: timesByAxis?.[1]?.[date]?.[time]?.id,
                    2: timesByAxis?.[2]?.[date]?.[time]?.id,
                    3: timesByAxis?.[3]?.[date]?.[time]?.id,
                },
            });
            if (lastTen.length === 10) {
                return lastTen;
            }
        }
    }
    return lastTen;
};

export const useFFTTimestampsQuery = ({
    equipmentId,
    selectorFun = null,
    installationPointId = null,
    unixFrom = Helper.findCommence(MTIME_TWO_MONTH),
    unixTo = moment().utc().unix(),
    keepPreviousData,
    enabled = true,
}) => {
    //TODO provide algorithm to merge sliced parts by from/to params in same store
    // const selectedRange = +_get(auth, ["customer", "customer_id"]) === 313 ? MTIME_SIX_MONTHS : MTIME_TWO_YEARS;
    const {isSuccess, data, isLoading, ...rest} = useQuery(
        [
            FFT_TIMESTAMPS_API.FFT_TIMESTAMPS_EQUIPMENT,
            {
                equipmentId: +equipmentId,
                installationPointId,
                unixFrom: moment.unix(unixFrom).format("MM/DD/YYYY"),
                unixTo: moment.unix(unixTo).format("MM/DD/YYYY"),
            },
        ],
        () => ApiReading.getFFTTimestampsList(+equipmentId, unixFrom, unixTo, installationPointId).then((timestamps) => parseApiResult(timestamps)),
        {
            select: selectorFun,
            enabled: !!equipmentId && enabled,
            keepPreviousData,
            placeholderData: {
                fftTimestamps: {},
                fftTimestampsImpactVue: {},
            },
        }
    );
    return {isSuccess, data, isLoading, ...rest};
};
