import React from "react";
import {SENSOR_TYPES} from "../../../constants/constants";
import PropTypes from "prop-types";
import {get as _get, each as _each, sortBy as _sortBy} from "lodash";
import FMaxSelect from "./FMaxSelect";
import LinesOfResolutionSelect from "./LinesOfResolutionSelect";
import ImpactVueText from "../../../shared/impactVue/ImpactVueText";

const FftSettingsForm = ({settings, /*specs, */ onChange, version_type, formErrors, sensorParameters, isHfdvue}) => {
    const maxLor = +_get(sensorParameters, "maxLor", 3750);
    const key = isHfdvue ? "fft_settings." + _get(settings, "axis_id", "0") + ".hfdvue_" : "fft_settings." + _get(settings, "axis_id", "0") + ".";
    const valueKey = isHfdvue ? "hfdvue_" : "";

    let lorValues = _get(sensorParameters, "lines_of_resolution", []);
    let lor = +_get(settings, valueKey + "lines_of_resolution");
    const fMax = +_get(settings, valueKey + "fmax");
    const maxAcquisitionTime = _get(sensorParameters, "maxAcquisitionTime", 60);

    if (isHfdvue) {
        const fMaxValues = _get(sensorParameters, "impactvue_fmax", []) || [];
        let newLorValues = [];
        _each(lorValues, (lorValue) => {
            newLorValues.push(lorValue);
        });
        _each(fMaxValues, (fMaxValue) => {
            let exist = false;
            _each(newLorValues, (lorValue) => {
                if (+lorValue > fMaxValue * maxAcquisitionTime) {
                    exist = true;
                    return;
                }
            });
            if (exist) {
                newLorValues.push(fMaxValue * maxAcquisitionTime);
            }
        });
        if (lor / fMax > maxAcquisitionTime) {
            onChange({
                target: {
                    name: key + "lines_of_resolution",
                    value: fMax * maxAcquisitionTime,
                },
            });
        }
        lorValues = _sortBy(newLorValues);
    }

    return (
        <React.Fragment>
            <div className="fft-settings">
                {settings?.axis_name !== null ? (
                    <div className="axis-divider">
                        <b>{settings?.axis_name}-axis</b>
                    </div>
                ) : (
                    ""
                )}
                <div
                    className="form-group"
                    style={{marginBottom: 20}}
                >
                    <div className="row mb-3">
                        <label className="col-5 text-right pt-2">{isHfdvue && <ImpactVueText type={"fft"} />} FMax:</label>
                        <div className="col-7">
                            <FMaxSelect
                                name={key + "fmax"}
                                sensorParameters={sensorParameters}
                                value={fMax}
                                onChange={onChange}
                                errorMsg={_get(formErrors, key + "fmax")}
                                disabled={settings?.axis_name !== "x"}
                                isHFDvue={isHfdvue}
                                versionType={version_type}
                            />
                        </div>
                    </div>
                </div>
                {settings?.axis_name === "x" && (
                    <>
                        <div
                            className="form-group"
                            style={{marginBottom: 15}}
                        >
                            <div className="row mb-3">
                                <label className="col-5 text-right pt-2">{isHfdvue && <ImpactVueText type={"fft"} />} Lines of Resolution:</label>
                                <div className="col-7">
                                    <LinesOfResolutionSelect
                                        name={key + "lines_of_resolution"}
                                        maxValue={maxLor}
                                        value={lor}
                                        values={lorValues}
                                        curFmax={fMax}
                                        maxAcquisitionTime={maxAcquisitionTime}
                                        onChange={onChange}
                                        errorMsg={_get(formErrors, key + "lines_of_resolution")}
                                        isHFDvue={isHfdvue}
                                        menuPortalTarget={document.querySelector(".modal")}
                                    />
                                </div>
                            </div>
                        </div>
                        {_get(formErrors, "fft_settings." + (settings?.axis_id || "0")) && (
                            <div className={"color-danger"}>{_get(formErrors, "fft_settings." + (settings?.axis_id || "0"))}</div>
                        )}
                    </>
                )}
            </div>
            {settings?.axis_name === "x" && (
                <>
                    {+version_type === SENSOR_TYPES.V2 && +settings?.lines_of_resolution >= 3750 && (
                        <div className="text-primary">Note: Higher Lines of Resolution impact the battery life.</div>
                    )}
                </>
            )}
        </React.Fragment>
    );
};

FftSettingsForm.propTypes = {
    settings: PropTypes.object,
    fftSettings: PropTypes.array,
    fftAxes: PropTypes.bool,
    specs: PropTypes.object,
    onChange: PropTypes.func,
    version_type: PropTypes.number,
    formErrors: PropTypes.object,
    index: PropTypes.number,
    sensorParameters: PropTypes.object,
    isHfdvue: PropTypes.bool,
};

export default FftSettingsForm;
