import {
    useSelectChartIsFaultFrequencyDetectionMode,
    useSelectChartPointsForDetection,
    useSelectChartSelectedPoint,
} from "../../store/hooks/useStoreContext";
import {useChartRef} from "../useChartRef";
import ChartHelper from "../../../../../../helpers/chart";
import {get as _get} from "lodash";
import FrequencyConverter from "../../../../../../helpers/frequency-converter";
import {BEARING_TYPES} from "../../../../../../constants/constants";
import {useSpeedSettings} from "../helpers/useSpeedSettings";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useInstallPointFaultFrequency} from "../../../../../../hooks/api/faultFrequency/useFaultFrequencyQuery";

const buildPlotLine = ({value, index, color = "black", isDetected}) => ({
    width: 2,
    value,
    dashStyle: isDetected ? "line" : "dash",
    color: color,
    label: {
        useHTML: true,
        text: `C${index}`,
        style: {
            color: "white",
            backgroundColor: color || "red",
            marginLeft: 5 + "px",
            padding: "0 2px",
            "z-index": 2,
            "font-size": "10px",
        },
        x: -5,
        y: 0,
    },
});
const ORDER_FILTER_VALUE = 0.1;

export const useFaultFrequencyDetectionPlotLine = () => {
    const pointsForDetection = useSelectChartPointsForDetection();
    const chartRef = useChartRef();
    const isFaultFrequencyDetectionMode = useSelectChartIsFaultFrequencyDetectionMode();
    const {currentSpeed} = useSpeedSettings();
    const selectedPoint = useSelectChartSelectedPoint();
    const {equipmentItem} = useEquipmentByParams();
    const {filteredFaultFrequencies} = useInstallPointFaultFrequency(equipmentItem.id, selectedPoint);

    const bearings = _get(
        equipmentItem.installationPoints.filter((installationPoint) => installationPoint.id === +selectedPoint),
        "0.bearings",
        []
    );

    const calculateBearings = (chartX) => {
        if (!currentSpeed) {
            return [];
        }

        const order = FrequencyConverter.fromHz(chartX, currentSpeed).toOrders().value;
        const pickedBearings = [];

        bearings.forEach((item) => {
            BEARING_TYPES.forEach((bearingName) => {
                for (let i = 1; i <= item.plot_lines_count; i++) {
                    const orderPerTick = item[bearingName] * i;

                    if (orderPerTick <= order + ORDER_FILTER_VALUE && orderPerTick >= order - ORDER_FILTER_VALUE) {
                        pickedBearings.push({bearingName, tickIndex: i});
                    }
                }
            });
        });

        return pickedBearings;
    };

    const calculateFaultFrequency = (chartX, readingOptions) => {
        const currentFrequencyValue = currentSpeed ? FrequencyConverter.fromHz(chartX, currentSpeed).toOrders().value : chartX;
        const pickedBearings = [];
        const tickFilterValue = currentSpeed ? ORDER_FILTER_VALUE : (2 * readingOptions.fmax) / readingOptions.lor;

        filteredFaultFrequencies.forEach((item) => {
            for (let i = 1; i <= item.plot_lines_count; i++) {
                const orderPerTick =
                    (currentSpeed
                        ? FrequencyConverter.fromType(item.frequency_type, item.frequency, currentSpeed).toOrders().value
                        : FrequencyConverter.fromType(item.frequency_type, item.frequency, currentSpeed).toHz().value) * i;

                if (orderPerTick <= currentFrequencyValue + tickFilterValue && orderPerTick >= currentFrequencyValue - tickFilterValue) {
                    pickedBearings.push({faultName: item.name, tickIndex: i});
                }
            }
        });

        return pickedBearings;
    };

    if (!isFaultFrequencyDetectionMode) {
        return [];
    }

    const chartAxis = chartRef?.current?.chart?.xAxis?.[0];
    const series = chartRef?.current?.chart?.series;

    if (!chartAxis) {
        return [];
    }

    const plotLines = pointsForDetection
        .map((pointForDetection, index) => {
            const {x} = pointForDetection;

            return {
                value: x,
                index: index + 1,
            };
        })
        .flatMap((item) =>
            series
                ?.filter((seriesItem) => item.value < seriesItem.xData.lastItem && !!seriesItem.userOptions.axisId)
                .map((seriesItem) => {
                    const index = ChartHelper.getNearestPointIndex(seriesItem, item.value, true, true);

                    const [value] = seriesItem.userOptions.data[index] || [];
                    const [prevValue] = seriesItem.userOptions.data[index - 1] || [];
                    const diff = value - prevValue;
                    const diffForClick = value - item.value;

                    const bearings = calculateBearings(value);
                    const faultFrequencies = calculateFaultFrequency(value, seriesItem.userOptions);

                    if (diff / 2 < diffForClick) {
                        const [valueS] = seriesItem.userOptions.data[index - 1] || [];

                        return {
                            index: item.index,
                            value: valueS,
                            color: seriesItem.color,
                            isDetected: !!bearings.length || !!faultFrequencies.length,
                        };
                    }

                    return {
                        index: item.index,
                        value,
                        color: seriesItem.color,
                        isDetected: !!bearings.length || !!faultFrequencies.length,
                    };
                })
        )
        .map(({value, index, color, isDetected}) => buildPlotLine({value, index: index, color, isDetected}));

    return {
        xAxisPlotLines: plotLines,
    };
};
