/* eslint-disable */
import $ from "jquery";
import Highcharts from "highcharts";
import {
    filter as _filter,
    find as _find,
    get as _get,
    includes as _includes,
    orderBy as _orderBy,
    range as _range,
    sortedLastIndexBy as _sortedLastIndexBy,
} from "lodash";
import Helper from "./helper";
import {DATEFORMAT, RPM_FROM, SPEED_TYPES, FREQUENCY_TYPES} from "../constants/constants";
import FrequencyConverter from "./frequency-converter";
import moment from "moment";

class ChartHelper {
    static clearAdditionalPoints(chart, listCharts = false) {
        if (listCharts) {
            $(document).find(".chart-block-fft-waterfall-wrapper .point-sideband").remove();
            $(document).find(".chart-block-fft-waterfall-wrapper .point-b-cursor").remove();
            $(document).find(".chart-block-fft-waterfall-wrapper .harmonic").remove();
            $(document).find(".chart-block-fft-waterfall-wrapper .sideBand").remove();
        } else {
            $(chart.renderTo).find(".point-sideband").remove();
            $(chart.renderTo).find(".point-b-cursor").remove();
            $(chart.renderTo).find(".harmonic").remove();
            $(chart.renderTo).find(".sideBand").remove();
        }
    }

    static tooltipPositioner(labelWidth) {
        if (this.chart.isMoved) {
            return {x: this.chart.movedX, y: this.chart.movedY};
        }
        if (
            this.chart.options.chart.chartType === "rms" ||
            this.chart.options.chart.chartType === "range" ||
            this.chart.options.chart.chartType === "tach"
        ) {
            return {x: 20, y: this.chart.plotTop + 25};
        }
        /*if (this.chart.hoverPoint.clientX > (this.chart.chartWidth / 2)) {
            return {x: this.chart.plotLeft + 20, y: this.chart.plotTop + 25};
        }*/
        return {x: this.chart.chartWidth - (labelWidth + 20), y: this.chart.plotTop + 25};
    }

    static formatTooltip() {
        let format = '<div class="chart-tooltip">';
        let datetimeFormat = Object.keys(DATEFORMAT)[0];

        let tachPoint = this.points.find((point) => point.series.userOptions.id === "tachSeria");

        if (typeof tachPoint !== "undefined") {
            format +=
                '<span><span style="color: ' +
                tachPoint.color +
                '; margin-bottom: 5px;">' +
                tachPoint.series.name +
                "</span>: <b>" +
                Helper.numberFormat(tachPoint.y, tachPoint.series.userOptions.precision) +
                "</b> " +
                tachPoint.series.userOptions.units +
                "</span><hr/>";
        }
        let seriesAlreadyParsed = [];
        _orderBy(this.points, "y", "desc").map((point) => {
            if (point.series.userOptions.id !== "tachSeria" && !_includes(seriesAlreadyParsed, point.series.userOptions.id)) {
                seriesAlreadyParsed.push(point.series.userOptions.id);
                datetimeFormat = point.series.userOptions.datetimeFormat;

                format += '<div style="height: 20px;">';
                format += "<span>" + Helper.dateToFormat(point.x, datetimeFormat) + "</span> ";
                format +=
                    '<span><span style="color: ' +
                    point.color +
                    '">' +
                    point.series.name +
                    "</span>: <b>" +
                    Helper.numberFormat(point.y, point.series.userOptions.precision) +
                    "</b> " +
                    point.series.userOptions.units +
                    "</span>";
                format += ChartHelper.getMarkerHTML(point.series.symbol, point.color);
                if (point.series.userOptions.idle_threshold && +point.series.userOptions.idle_threshold > +point.y) {
                    format += " - Below idle threshold (" + point.series.userOptions.idle_threshold + ")";
                }
                format += "</div>";
            }
        });

        format += '<button type="button" class="pull-right movable drag-tooltip-btn"><i class="fa fa-arrows-alt"></i></button>';
        format += "</div>";
        return format;
    }

    static formatTachTooltip() {
        let format = '<div class="chart-tooltip">';
        let datetimeFormat = Object.keys(DATEFORMAT)[0];

        let tachPoint = this.points.find((point) => point.series.userOptions.id === "tachSeria");

        if (typeof tachPoint !== "undefined") {
            format +=
                '<span><span style="color: ' +
                tachPoint.color +
                '; margin-bottom: 5px;">' +
                tachPoint.series.name +
                "</span>: <b>" +
                Helper.numberFormat(tachPoint.y, tachPoint.series.userOptions.precision) +
                "</b> " +
                tachPoint.series.userOptions.units +
                "</span><br/>" +
                "<span>Tachometer RPM x Ratio = Point RPM</span>" +
                "<hr/>";
        }

        _orderBy(this.points, "y", "desc").map((point) => {
            if (point.series.userOptions.id !== "tachSeria") {
                datetimeFormat = point.series.userOptions.datetimeFormat;
                format +=
                    '<span><span style="color: ' +
                    point.color +
                    '">' +
                    point.series.name +
                    " </span> | Ratio: " +
                    point.series.userOptions.ratioValue +
                    " | Point RPM: <b>" +
                    Helper.numberFormat(point.y, point.series.userOptions.precision) +
                    "</b> " +
                    point.series.userOptions.units +
                    "</span><br/>";
            }
        });

        format += "<span>" + Helper.dateToFormat(this.x, datetimeFormat) + "</span>";
        format += '<button type="button" class="pull-right movable drag-tooltip-btn"><i class="fa fa-arrows-alt"></i></button>';
        format += "</div>";
        return format;
    }

    static syncExtremes(e) {
        let thisChart = e.target.chart;

        if (!thisChart || thisChart.withoutExtremesSync) {
            return;
        }

        if (["syncExtremes", "zoom", "resetZoom", "syncZoom"].includes(e.trigger)) {
            return;
        }

        Highcharts.each(Highcharts.charts, function (chart) {
            if (ChartHelper.shouldNotExtremesBeSynced(chart, thisChart)) {
                return;
            }

            chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {trigger: "syncExtremes"});
        });
    }

    static shouldExtremesBeSynced(chart, thisChart) {
        if (!chart) {
            return false;
        }

        if (chart === thisChart) {
            return false;
        }

        if (!$(chart.renderTo).hasClass("simple-chart")) {
            return false;
        }

        if (!chart.xAxis[0].setExtremes) {
            return false;
        }

        return true;
    }

    static shouldNotExtremesBeSynced(chart, thisChart) {
        return !ChartHelper.shouldExtremesBeSynced(chart, thisChart);
    }

    static formatXAxisFFT() {
        let type = this.axis.userOptions.currentFrequency || FREQUENCY_TYPES.HZ;
        const precision = this.axis.userOptions.currentFrequency === FREQUENCY_TYPES.ORDERS ? 2 : 0;
        const currentSpeed = this.axis.userOptions.currentSpeed || 0;

        if (type === FREQUENCY_TYPES.ORDERS && currentSpeed <= 0) type = FREQUENCY_TYPES.HZ;

        return FrequencyConverter.fromHz(this.value, currentSpeed)
            .toType(type)
            .numberFormat(precision, {withX: true, rounded: type === FREQUENCY_TYPES.ORDERS});
    }

    static formatXAxis() {
        let labelFormat = this.dateTimeLabelFormat;
        const yearFormat = "'%y";
        if (moment(this.pos).year() < moment().year() && labelFormat.indexOf(yearFormat) === -1) {
            return moment(this.value).format("DD MMM YYYY");
        }

        return Highcharts.dateFormat(labelFormat, this.value);
    }

    static fftTicksPositioner() {
        if (this.userOptions.currentFrequency !== FREQUENCY_TYPES.ORDERS) {
            return;
        }

        var runningSpeed = this.userOptions.currentSpeed;
        if (runningSpeed > 0 && this.dataMax > 1) {
            const MAX_SLICE_COUNT = 10;

            const hzOrder = FrequencyConverter.fromCpm(runningSpeed).toHz().value;

            const hzDataMaxRaw = this.max;
            const hzDataMinRaw = this.min;

            const DataMaxInOrders = Math.ceil(FrequencyConverter.fromHz(hzDataMaxRaw, runningSpeed).toOrders().value);
            const DataMinInOrders = Math.ceil(FrequencyConverter.fromHz(hzDataMinRaw, runningSpeed).toOrders().value);

            const hzDataMin = FrequencyConverter.fromOrders(DataMinInOrders, runningSpeed).toHz().value;

            let result = [];
            let step = hzOrder;

            const stepInOrders = Math.ceil((DataMaxInOrders - DataMinInOrders) / MAX_SLICE_COUNT);

            step = FrequencyConverter.fromOrders(stepInOrders, runningSpeed).toHz().value;

            for (let i = hzDataMin; i < hzDataMaxRaw; i += step) {
                result.push(i);
            }
            // if highchart draw pointer behind min value
            if (result[0] < 0) {
                result.splice(0, 1, 0);
            }

            return result;
        }

        return;
    }

    static formatYAxis3dTrend() {
        return Highcharts.numberFormat(this.value, 4, ".", "");
    }

    static formatTooltipFFT({hoverPoints, cursor, lockPointsRef, frequencyUnits, countHarmonics = null, type = "FFT", chartType}) {
        const lockPoints = lockPointsRef.current;

        if (lockPoints?.length && cursor === "harmonic") {
            hoverPoints = lockPoints;
        }

        if (!hoverPoints) return null;

        if (!Array.isArray(hoverPoints)) {
            hoverPoints = hoverPoints.hoverPoints;
        }

        let points = _orderBy(hoverPoints, (point) => point?.series?.options?.id, "desc");
        points = _orderBy(points, (point) => point?.series?.userOptions?.isOverlay, "asc");

        const rows = [];
        if (cursor === "harmonic" && +countHarmonics !== 0) {
            for (let key in points) {
                let row = "";

                const point = points[key];
                const userOptions = point?.series?.userOptions;

                if (!userOptions) {
                    continue;
                }
                if (point.index < 2 && type === "FFT") {
                    return false;
                }

                const seria = point.series;

                if (+key === 0 && !userOptions.isStackedWaterfall) {
                    row += ChartHelper.getRpmFormat(userOptions);
                }

                row += '<div class="harmonic-modal-title mb-2">Harmonics</div>';
                row += `<div class="harmonic-row">
                    <span>Main Harmonic</span>
                    <span>
                        ${FrequencyConverter.fromHz(point.x).format()}
                        ${FrequencyConverter.fromHz(point.x).toCpm().format()}
                        ${userOptions.speed > 0 ? FrequencyConverter.fromHz(point.x, userOptions.speed).toOrders().format(2, {withX: true}) : ""}
                    </span>
                    <span><b>${Helper.numberFormat(point.y, point.series.userOptions.precision)} ${userOptions.units}</b></span>
                </div>`;

                row += `<div class="harmonic-row">
                    <span>Max Harmonics</span>
                    <span><b>${countHarmonics}</b></span>
                </div>`;

                row += '<div class="harmonic-dots-container">';
                row += '<ol class="harmonic-list">';

                if (seria && seria.visible === true && seria.userOptions.enableMouseTracking === true) {
                    let data_max = hoverPoints[0].series.xAxis.dataMax;
                    let count = Math.ceil(data_max / point.x);

                    for (let i = 1; i < count; i++) {
                        if (i !== 1 && i <= countHarmonics) {
                            let value = point.x * i;

                            let valueX;

                            if (frequencyUnits === FREQUENCY_TYPES.ORDERS) {
                                valueX =
                                    userOptions.speed > 0 ? FrequencyConverter.fromHz(value, userOptions.speed).toOrders().format(2, {withX: true}) : "";
                            } else if (frequencyUnits === FREQUENCY_TYPES.CPM) {
                                valueX = FrequencyConverter.fromHz(value).toCpm().format();
                            } else {
                                valueX = FrequencyConverter.fromHz(value).format();
                            }

                            let valueY = null;

                            let closest_key = ChartHelper.getNearestPointIndex(seria, value);

                            let highest_y = null;
                            for (let k = closest_key - 2; k < closest_key + 3; k++) {
                                if (seria.points[k]) {
                                    if (highest_y === null || seria.points[k].y > highest_y) {
                                        highest_y = seria.points[k].y;

                                        valueY = `<b>${Helper.numberFormat(highest_y, point.series.userOptions.precision)} ${userOptions.units}</b>`;
                                    }
                                }
                            }

                            row += `<li>
                                ${valueX} ${valueY}
                            </li>`;
                        }
                    }
                }

                row += "</ol>";
                row += "</div>";

                rows.push(row);
            }
        } else {
            for (let key in points) {
                let row = "";
                const point = points[key];
                if (!point?.series?.userOptions) {
                    continue;
                }
                const userOptions = {...point.series.userOptions};

                if (+key === 0 && !userOptions.isStackedWaterfall) {
                    row += ChartHelper.getRpmFormat(userOptions);
                }

                if (userOptions.isOverlay) {
                    row += `<span>Overlay date: <b>${userOptions.readingDate}</b></span><br/>`;
                }

                row += `<span>Frequency:
                            ${FrequencyConverter.fromHz(point.x).format()}
                            ${FrequencyConverter.fromHz(point.x).toCpm().format()}
                            ${userOptions.speed > 0 ? FrequencyConverter.fromHz(point.x, userOptions.speed).toOrders().format(2, {withX: true}) : ""}
                        </span>
                        <br/>
                `;

                row += `<span><span style="color: ${point.color}">${point.series.name}</span> ${chartType === "9" ? "PK:" : ""} <b>${Helper.numberFormat(
                    point.y,
                    point.series.userOptions.precision
                )} ${userOptions.units}</b></span>`;
                row += `<span>, RMS: <b>${Helper.numberFormat(userOptions.overall, point.series.userOptions.precision)} ${userOptions.units} </b></span>`;

                row += ChartHelper.getSidebandFormat(point, cursor, lockPoints, frequencyUnits, userOptions.speed);
                row += ChartHelper.getBCursorFormat(point, cursor, lockPoints, frequencyUnits, userOptions.speed);
                rows.push(row);
            }
        }

        rows.push('<button type="button" class="pull-right movable drag-tooltip-btn"><i class="fa fa-arrows-alt"></i></button>');

        return '<div class="chart-tooltip">' + rows.join("<hr/>") + "</div>";
    }

    static formatTooltipTWF({hoverPoints, cursor, lockPointsRef}) {
        const lockPoints = lockPointsRef.current;

        if (!hoverPoints) return null;

        let points = _orderBy(hoverPoints, (point) => point?.series?.options?.id, "desc");
        points = _orderBy(points, (point) => point?.series?.userOptions?.isOverlay, "asc");

        const rows = [];
        for (let key in points) {
            let row = "";
            const point = points[key];

            const userOptions = point.series.userOptions;

            if (+key === 0) {
                row += ChartHelper.getRpmFormat(userOptions);
            }

            if (userOptions.isOverlay) {
                row += `<span>Overlay date: <b>${userOptions.readingDate}</b></span><br/>`;
            }

            if (point.series.userOptions.isCircular === false) {
                row += `<span>Time: <b>${Helper.numberFormat(point.x, 5)} sec</b></span> <br/>`;
            }

            row += `<span><span style="color: ${point.color}">${point.series.name}:</span> <b>${Helper.numberFormat(
                point.y,
                point.series.userOptions.precision
            )} ${userOptions.units}</b></span><br/>`;
            row += `<span>RMS: <b>${Helper.numberFormat(userOptions.overall, point.series.userOptions.precision)} ${userOptions.units}</b></span>`;
            row += `<span>, PK-PK: <b>${Helper.numberFormat(userOptions.pkpk, point.series.userOptions.precision)} ${userOptions.units}</b></span><br/>`;

            row += ChartHelper.getSidebandFormat(point, cursor, lockPoints, "sec", null);
            row += ChartHelper.getBCursorFormat(point, cursor, lockPoints, "sec");

            rows.push(row);
        }
        rows.push('<button type="button" class="pull-right movable drag-tooltip-btn"><i class="fa fa-arrows-alt"></i></button>');

        return '<div class="chart-tooltip">' + rows.join("<hr/>") + "</div>";
    }

    static getRpmFormat(userOptions) {
        let result = "";
        if (+userOptions.speed > 0 || userOptions.rpmFrom === RPM_FROM.TACHOMETER) {
            result += `<span>${userOptions.rpmFrom} RPM <b>${Helper.numberFormat(userOptions.speed, 2)}</b></span>`;
            const speed = +userOptions.speed / (+userOptions.ratio || 1);
            if (+userOptions.maxRpm > 0 && speed > +userOptions.maxRpm) {
                result += '&nbsp;<span style="color: red">(Over Max RPM)</span>';
            }
            result += "<br/>";
            if (userOptions.rpmTimestamp) {
                result += `<span>RPM Timestamp: <b>${userOptions.rpmTimestamp}</b></span>`;
            }

            result += "<hr/>";
        }

        return result;
    }

    static formatTooltip3dTrend(chart, precision, units) {
        const rows = [`<span>${chart.hoverPoint.category}</span>`, `<span><b>${Helper.numberFormat(chart.hoverPoint.y, precision)}</b> ${units}</span>`];

        return `<div class="chart-tooltip">${rows.join("<br/>")}</div>`;
    }

    static getSidebandFormat(point, cursor, lockPoints, xUnits, speed) {
        const userOptions = point.series.userOptions;
        let format = "";
        let dfrqUnits;

        if (cursor === "sideband" && lockPoints.length) {
            const sideBandCursor = _find(lockPoints, {series: point.series});
            if (sideBandCursor) {
                let sidebandMark, dtime, dfrq;
                if (xUnits === "sec") {
                    sidebandMark = Helper.numberFormat(sideBandCursor.x, 4);
                    dtime = Helper.numberFormat(point.x - sideBandCursor.x, 4);
                    dfrq = Math.abs(Helper.numberFormat(1 / dtime, 4));
                    dfrqUnits = FREQUENCY_TYPES.HZ;
                } else {
                    sidebandMark = FrequencyConverter.fromHz(sideBandCursor.x, speed).toType(xUnits).numberFormat(4);
                    dfrq = Math.abs(
                        FrequencyConverter.fromHz(point.x - sideBandCursor.x, speed)
                            .toType(xUnits)
                            .numberFormat(4)
                    );
                    dfrqUnits = xUnits;
                }

                format += `<br/><span>Sideband mark: <b>${sidebandMark} ${xUnits}</b>, <b>${Helper.numberFormat(parseFloat(sideBandCursor.y), 4)} ${
                    userOptions.units
                }</b></span><br/>`;
                if (dtime !== undefined) {
                    format += `<span>Dtime:<b>${dtime} sec</b></span>`;
                }
                if (dfrq !== undefined && isNaN(dfrq) === false) {
                    format += ` | <span>Dfrq:<b>${dfrq} ${dfrqUnits}</b></span><br/>`;
                }
            }
        }
        return format;
    }

    static getBCursorFormat(point, cursor, lockPoints, xUnits, speed) {
        const userOptions = point.series.userOptions;
        let format = "";

        if (cursor === "bCursor" && lockPoints.length) {
            const bCursor = _find(lockPoints, {series: point.series});
            if (bCursor) {
                const cursorB =
                    xUnits === "sec" ? Helper.numberFormat(bCursor.x, 4) : FrequencyConverter.fromHz(bCursor.x, speed).toType(xUnits).numberFormat(4);

                const diff =
                    xUnits === "sec"
                        ? Helper.numberFormat(bCursor.x - point.x, 4)
                        : FrequencyConverter.fromHz(bCursor.x - point.x, speed)
                              .toType(xUnits)
                              .numberFormat(4);

                format += `<br/><span>Cursor B: <b>${cursorB} ${xUnits} ${Helper.numberFormat(parseFloat(bCursor.y), userOptions.precision)} ${
                    userOptions.units
                }</b></span><br/>`;
                format += `<span>Diff: <b>${diff} ${xUnits} ${Helper.numberFormat(bCursor.y - point.y, userOptions.precision)} ${
                    userOptions.units
                }</b></span><br/>`;
            }
        }
        return format;
    }

    static getReadingSpeed(fftReading) {
        if (fftReading && fftReading.rpm !== false) {
            return {
                value: +fftReading.rpm,
                ratio: +fftReading.rpmRatio,
                from: fftReading.rpmFrom,
                rpmTimestamp: fftReading.rpmTimestamp,
            };
        }
        return false;
    }

    static getPointSpeed(pointData, equipment) {
        let speedRatio = _get(pointData, "speed_ratio", 1);
        if (speedRatio === null) {
            speedRatio = 1;
        }
        if (equipment?.speedType[0] && equipment.speedType[0].id == SPEED_TYPES.RATIO) {
            return {
                value: +equipment.current_speed * speedRatio,
                ratio: +speedRatio,
                from: RPM_FROM.SENSOR,
            };
        } else {
            if (pointData.speed > 0) {
                return {
                    value: +pointData.speed,
                    ratio: +speedRatio,
                    from: RPM_FROM.SENSOR,
                };
            } else if (equipment.current_speed > 0) {
                return {
                    value: +equipment.current_speed,
                    ratio: +speedRatio,
                    from: RPM_FROM.EQUIPMENT,
                };
            }
        }

        return {
            value: 0,
            ratio: 1,
            from: "Sensor",
        };
    }

    static getCurrentSpeed(fftReading, pointData, equipment) {
        if (fftReading && fftReading.rpm > 0 && fftReading.rpm !== false) {
            const readingSpeed = ChartHelper.getReadingSpeed(fftReading);
            if (readingSpeed) {
                return readingSpeed;
            }
        }

        return {...ChartHelper.getPointSpeed(pointData, equipment), rpmTimestamp: fftReading?.rpmTimestamp};
    }

    static sideBandCursor(sideBandPoints, hoverPoints) {
        $(".sideBand").remove();
        for (let sideBandPoint of sideBandPoints) {
            for (let hoverPoint of hoverPoints) {
                if (sideBandPoint.series !== hoverPoint.series) {
                    continue;
                }

                let chart = hoverPoint.series.chart,
                    xData = sideBandPoint.series.xData,
                    sideBandIndex = xData.indexOf(sideBandPoint.x),
                    renderer = chart.renderer,
                    rectCount = 5,
                    step,
                    left,
                    top,
                    highlightedIndexes;

                step = Math.abs(xData.indexOf(hoverPoint.x) - xData.indexOf(sideBandPoint.x));

                highlightedIndexes = _range(sideBandIndex - step * rectCount, sideBandIndex + step * (rectCount + 1), step);

                highlightedIndexes.map((index) => {
                    if (hoverPoint.series.xData[index]) {
                        left = chart.xAxis[0].toPixels(hoverPoint.series.xData[index]) - 4;
                        top = chart.yAxis[0].toPixels(hoverPoint.series.yData[index]) - 4;

                        renderer
                            .rect(left, top, 8, 8, 1)
                            .attr({
                                stroke: "black",
                                "stroke-width": 1,
                                "point-id": index,
                                zIndex: 5,
                            })
                            .addClass("sideBand")
                            .add();
                    }
                });
            }
        }
    }

    static getNearestPointIndex(series, x, isRawSeriesSelect = false, isUseData = false) {
        // hotfix: negative xx comes from fft server
        x = Math.max(x, 0);

        // _sortedLastIndexBy uses binary search. Got performance raise 10x+ compared to Highcharts.each
        let index = [];

        if (isUseData) {
            series = series.options.data;
        }

        if (!isRawSeriesSelect) {
            index = _sortedLastIndexBy(series.points, {x: x}, (point) => point.x);
        } else {
            index = _sortedLastIndexBy(series, {0: x}, (point) => point[0]);
        }

        index = Math.min(index, (isRawSeriesSelect ? series : series.points).length - 1);

        const diff = (isRawSeriesSelect ? series : series.points)[index].x - x;

        if (index > 0) {
            const prevDiff = x - (isRawSeriesSelect ? series : series.points)[index - 1].x;
            if (diff >= prevDiff) {
                index = index - 1;
            }
        }
        return index;
    }

    static harmonicCursor(points, type = "FFT", limitPoints = null) {
        const chart = points[0].series.chart;
        $(chart.renderTo).find(".harmonic").remove();
        for (let point of points) {
            if (point.index < 2 && type === "FFT") {
                return false;
            }

            const seria = point.series;
            const renderer = chart.renderer;

            if (type === "TWF" && seria.processedXData.indexOf(point.x) !== -1 && seria.processedXData.indexOf(point.x) < 1) {
                return false;
            }

            if (seria && seria.visible === true && seria.userOptions.enableMouseTracking === true) {
                let highest_key_y = point.index;
                let data_max = chart.xAxis[0].dataMax;

                let count = Math.ceil(data_max / point.x);
                let left = chart.xAxis[0].toPixels(point.x) - 4;
                let top = chart.yAxis[0].toPixels(point.y) - 4;
                if (+limitPoints !== 0) {
                    count = limitPoints;
                } else {
                    count = Math.ceil(data_max / point.x);
                }

                for (let i = 1; i <= count; i++) {
                    if (i !== 1) {
                        let value = point.x * i;
                        let closest_key = ChartHelper.getNearestPointIndex(seria, value);
                        let highest_y = null;

                        for (let k = closest_key - 2; k < closest_key + 3; k++) {
                            if (seria.points[k]) {
                                if (highest_y === null || seria.points[k].y > highest_y) {
                                    highest_y = seria.points[k].y;
                                    highest_key_y = k;
                                }
                            }
                        }

                        left = chart.xAxis[0].toPixels(seria.points[highest_key_y].x) - 4;
                        top = chart.yAxis[0].toPixels(seria.points[highest_key_y].y) - 4;
                    }

                    renderer
                        .rect(left, top, 8, 8, 1)
                        .attr({
                            stroke: "red",
                            "stroke-width": 1,
                            "point-id": highest_key_y,
                            zIndex: 5,
                        })
                        .addClass("harmonic")
                        .add();
                }
            }
        }
    }

    static handleNavigationFftTwf(event, chart, peaks, maxPeak) {
        if (!chart.hoverPoint?.series) {
            return;
        }
        const hoverPoint = chart.hoverPoint,
            visiblePoints = hoverPoint.series.points,
            hoverPointIndex = hoverPoint ? visiblePoints.indexOf(hoverPoint) : null;

        let newHoverPointIndex = hoverPointIndex;

        const isPeak = (i) => {
            if (!peaks[visiblePoints[i].index]) {
                return false;
            }
            const minPercentage = 0.5;

            const currentProminence = peaks[visiblePoints[i].index];
            const currentPercentage = (currentProminence / maxPeak) * 100;

            return currentPercentage > minPercentage;
        };

        if (event.keyCode === 37) {
            // arrow left
            if (event.shiftKey === true) {
                for (let i = newHoverPointIndex - 1; i > 0; i--) {
                    if (isPeak(i)) {
                        newHoverPointIndex = i;
                        break;
                    }
                }
            } else {
                newHoverPointIndex = Math.max(hoverPointIndex - 1, 0);
            }
        } else if (event.keyCode === 39) {
            // arrow right
            const pointsMaxKey = visiblePoints.length - 1;
            if (event.shiftKey === true) {
                for (let i = newHoverPointIndex + 1; i < visiblePoints.length - 1; i++) {
                    if (isPeak(i)) {
                        newHoverPointIndex = i;
                        break;
                    }
                }
            } else {
                newHoverPointIndex = Math.min(hoverPointIndex + 1, pointsMaxKey);
            }
        }

        if (visiblePoints[newHoverPointIndex]) {
            visiblePoints[newHoverPointIndex].onMouseOver();
        }
    }

    static handleNavigationAutoCorrelation(event, chart) {
        const hoverPoint = chart.hoverPoint,
            visiblePoints = hoverPoint.series.points,
            hoverPointIndex = hoverPoint ? visiblePoints.indexOf(hoverPoint) : null;

        let newHoverPointIndex = hoverPointIndex;

        const isPeak = (i) =>
            visiblePoints[i] &&
            visiblePoints[i + 1] &&
            visiblePoints[i - 1] &&
            visiblePoints[i].y > visiblePoints[i + 1].y &&
            visiblePoints[i].y > visiblePoints[i - 1].y;
        if (event.keyCode === 37) {
            // arrow left
            if (event.shiftKey === true) {
                for (let i = hoverPointIndex - 1; i > 0; i--) {
                    if (isPeak(i)) {
                        newHoverPointIndex = i;
                        break;
                    }
                }
            } else {
                newHoverPointIndex = Math.max(hoverPointIndex - 1, 0);
            }
        } else if (event.keyCode === 39) {
            // arrow right
            const pointsMaxKey = visiblePoints.length - 1;
            if (event.shiftKey === true) {
                for (let i = hoverPointIndex + 1; i < visiblePoints.length - 1; i++) {
                    if (isPeak(i)) {
                        newHoverPointIndex = i;
                        break;
                    }
                }
            } else {
                newHoverPointIndex = Math.min(hoverPointIndex + 1, pointsMaxKey);
            }
        }

        if (visiblePoints[newHoverPointIndex]) {
            visiblePoints[newHoverPointIndex].onMouseOver();
        }
    }

    static handleNavigationSimple(event, chart) {
        const hoverPoint = _filter(chart.hoverPoints, (hoverPointed) => {
            if (hoverPointed.color !== "rgba(135,135,145,0.5)") {
                return hoverPointed;
            }
        });
        const visiblePoints = hoverPoint[0].series.points;
        const hoverPointIndex = hoverPoint[0] ? visiblePoints.indexOf(hoverPoint[0]) : null;

        let newHoverPointIndex = hoverPointIndex;

        if (event.keyCode === 37) {
            // arrow left
            newHoverPointIndex = Math.max(hoverPointIndex - 1, 0);
        } else if (event.keyCode === 39) {
            // arrow right
            const pointsMaxKey = visiblePoints.length - 1;
            newHoverPointIndex = Math.min(hoverPointIndex + 1, pointsMaxKey);
        }

        $(".chart-label").addClass("d-none");
        const point = _get(visiblePoints, newHoverPointIndex);
        $(".tm-" + _get(point, "x")).removeClass("d-none");

        if (visiblePoints[newHoverPointIndex] && visiblePoints[newHoverPointIndex].y !== null) {
            visiblePoints[newHoverPointIndex].onMouseOver();
        }
    }

    static handleNavigation3d(event, hoverPoint, peaks, peakMaxCacher, hoverFunc) {
        const visiblePoints = hoverPoint.fullData,
            hoverPointIndex = hoverPoint.pointNumber;

        let newHoverPointIndex = hoverPointIndex;

        const isPeak = (i) => {
            if (!peaks[i]) {
                return false;
            }
            const minPercentage = 0.5;

            const currentProminence = peaks[i];
            const maxProminence = peakMaxCacher.value;
            const currentPercentage = (currentProminence / maxProminence) * 100;

            return currentPercentage > minPercentage;
        };

        if (event.keyCode === 37) {
            // arrow left
            if (event.shiftKey === true) {
                for (let i = newHoverPointIndex - 1; i > 0; i--) {
                    if (isPeak(i)) {
                        newHoverPointIndex = i;
                        break;
                    }
                }
            } else {
                newHoverPointIndex = Math.max(hoverPointIndex - 1, 0);
            }
        } else if (event.keyCode === 39) {
            // arrow right
            const pointsMaxKey = visiblePoints.y.length - 1;
            if (event.shiftKey === true) {
                for (let i = newHoverPointIndex + 1; i < visiblePoints.y.length - 1; i++) {
                    if (isPeak(i)) {
                        newHoverPointIndex = i;
                        break;
                    }
                }
            } else {
                newHoverPointIndex = Math.min(hoverPointIndex + 1, pointsMaxKey);
            }
        }

        hoverFunc(newHoverPointIndex);
    }

    static isAtMax(chart) {
        return chart.xAxis[0].getExtremes().max >= chart.xAxis[0].getExtremes().dataMax;
    }

    static moveToMax(chart) {
        const extremes = chart.xAxis[0].getExtremes();
        let dataMin = extremes.dataMax - chart.xAxis[0].options.range;
        if (dataMin < extremes.dataMin) {
            dataMin = extremes.dataMin;
        }
        chart.xAxis[0].setExtremes(dataMin, extremes.dataMax);
    }

    static initMovableTooltip(chartRef, chartWrapperSelector) {
        $(document).on("mousedown", chartWrapperSelector + " .movable", function (e) {
            if (!_get(chartRef, "current.chart")) {
                return;
            }

            const chart = _get(chartRef, "current.chart");

            chart.isPausedBefore = chart.isPaused;
            chart.isMoved = true;
            chart.isPaused = true;
            chart.moveMode = true;

            let element = $(this).closest(".highcharts-tooltip");
            let pos1 = 0;
            let pos2 = 0;
            let pos3 = e.clientX;
            let pos4 = e.clientY;
            let newPosX = 0;
            let newPosY = 0;

            $(document).on("mouseup", () => {
                if (!chart.isPausedBefore) {
                    chart.isPaused = false;
                }
                $(document).off("mouseup");
                $(document).off("mousemove");
            });

            $(document).on("mousemove", (e) => {
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;

                newPosX = parseInt(element.css("left")) - pos1;
                newPosY = parseInt(element.css("top")) - pos2;

                const tooltipWidth = element.find(".chart-tooltip").parent().width();
                const tooltipHeight = element.find(".chart-tooltip").parent().height();

                if (0 > newPosX) {
                    newPosX = 0;
                }
                if (chart.plotWidth - tooltipWidth < newPosX) {
                    newPosX = chart.plotWidth - tooltipWidth;
                }
                if (0 > newPosY) {
                    newPosY = 0;
                }
                if (chart.plotHeight - tooltipHeight < newPosY) {
                    newPosY = chart.plotHeight - tooltipHeight;
                }

                chart.movedX = newPosX;
                chart.movedY = newPosY;

                element.css({
                    top: newPosY,
                    left: newPosX,
                });
            });
        });
    }

    static detachMovableTooltip(chartWrapperSelector) {
        $(document).off("mousedown", chartWrapperSelector + " .movable");
    }

    static getPointBetween(leftPoint, rightPoint, x) {
        const slope = (leftPoint[1] - rightPoint[1]) / (leftPoint[0] - rightPoint[0]);
        const xDiff = x - leftPoint[0];

        return [x, leftPoint[1] + xDiff * slope];
    }

    static getMarkerHTML(pointMarker, color) {
        let markerHtml = "";
        switch (pointMarker) {
            case "circle":
                markerHtml = '<span style="padding-left: 5px;"><span style="color: ' + color + '; font-size: 13px;">●</span></span>';
                break;
            case "square":
                markerHtml = '<span style="padding-left: 5px;"><span style="color: ' + color + '; font-size: 13px;">■</span></span>';
                break;
            case "diamond":
                markerHtml = '<span style="padding-left: 5px;"><span style="color: ' + color + '; font-size: 13px;">♦</span></span>';
                break;
            case "triangle":
                markerHtml = '<span style="padding-left: 5px;"><span style="color: ' + color + '; font-size: 13px;">▲</span></span>';
                break;
            case "triangle-down":
                markerHtml = '<span style="padding-left: 5px;"><span style="color: ' + color + '; font-size: 13px;">▼</span></span>';
                break;
        }

        return markerHtml;
    }

    static getFetchChartType(chartType) {
        let chartTypeLocal = chartType;
        if (chartType == 19) {
            //If fft impactVue
            chartTypeLocal = 8;
        } else if (chartType == 20) {
            chartTypeLocal = 9;
        } else if (chartType == 21) {
            //If twf impactVue ( type = 21 / 22)
            chartTypeLocal = 7;
        } else if (chartType == 22) {
            chartTypeLocal = 11;
        } else {
            chartTypeLocal = chartType;
        }
        return chartTypeLocal;
    }
}

export default ChartHelper;
