import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {devtools, persist} from "zustand/middleware";
import {shallow} from "zustand/shallow";
import moment from "moment/moment";
import Helper from "../../helpers/helper";
import {cloneDeep as _cloneDeep, forEach as _forEach} from "lodash";

const defaultRange = JSON.parse(localStorage.getItem("defaultRangeSelector"));

const microTimeCalculation = (countDays = 1) => {
    return countDays * 24 * 3600 * 1000;
};

export const MTIME_ONE_DAY = microTimeCalculation();
export const MTIME_TWO_DAYS = microTimeCalculation(2);
export const MTIME_ONE_WEEK = microTimeCalculation(7);
export const MTIME_TWO_WEEKS = microTimeCalculation(14);
export const MTIME_ONE_MONTH = microTimeCalculation(30);
export const MTIME_TWO_MONTH = microTimeCalculation(60);
export const MTIME_THREE_MONTHS = microTimeCalculation(90);
export const MTIME_SIX_MONTHS = microTimeCalculation(180);
export const MTIME_ONE_YEAR = microTimeCalculation(365);
export const MTIME_TWO_YEARS = microTimeCalculation(730);

export const RANGE_VALUES = [
    {
        text: "1d",
        mtime: MTIME_ONE_DAY,
    },
    {
        text: "2d",
        mtime: MTIME_TWO_DAYS,
    },
    {
        text: "1w",
        mtime: MTIME_ONE_WEEK,
    },
    {
        text: "2w",
        mtime: MTIME_TWO_WEEKS,
    },
    {
        text: "1m",
        mtime: MTIME_ONE_MONTH,
    },
    {
        text: "2m",
        mtime: MTIME_TWO_MONTH,
    },
    {
        text: "3m",
        mtime: MTIME_THREE_MONTHS,
    },
    {
        text: "6m",
        mtime: MTIME_SIX_MONTHS,
    },
    {
        text: "1y",
        mtime: MTIME_ONE_YEAR,
    },
];

const defaultRangeSelector = {
    from: null,
    to: null,
    range: defaultRange === null ? MTIME_TWO_WEEKS : parseInt(defaultRange),
    isCustom: false,
};

const store = (set, get) => ({
    rangeSelector: defaultRangeSelector,
    bottomSettingsRangeSelector: RANGE_VALUES,
    currentChartMarkId: null,
    actions: {
        setSelectedRange: (rangeSelectorObject) => {
            set((state) => {
                state.rangeSelector = rangeSelectorObject;
            });
        },
        setBottomSettingsRangeSelector: (settingsRangeSelector) => {
            set((state) => {
                state.bottomSettingsRangeSelector = settingsRangeSelector;
            });
        },
        resetSelectedRange: () => {
            set((state) => {
                state.rangeSelector = getRangeSelectorObject(defaultRangeSelector);
            });
        },
        updateRangeToChartMark: (chartMark, isCurrentChartMark = false) => {
            let rangeSelector = _cloneDeep(get().bottomSettingsRangeSelector);
            const timestamp = moment(chartMark.timestamp).toDate().getTime();
            let dateRange = {
                isCustom: false,
                range: null,
            };

            _forEach(rangeSelector, (range) => {
                if (timestamp > +(Date.now() - range.mtime)) {
                    dateRange.range = range.mtime;
                    return false;
                }
            });

            if (!dateRange.range) {
                dateRange.isCustom = true;
                dateRange.from = moment.unix(chartMark.timestamp).subtract(7, "days");
                dateRange.to = moment.unix(chartMark.timestamp).add(7, "days");
            }

            set((state) => {
                state.currentChartMarkId = isCurrentChartMark ? chartMark.id : null;
                state.rangeSelector = getRangeSelectorObject(dateRange);
            });
        },
        setTimeRangeByAlert: (alert) => {
            const isFFT = alert.alertCondition.fft_alert_type === 1 || alert.alertCondition.fft_alert_type === 2;
            if (!isFFT) {
                const rangeSelectorsList = _cloneDeep(get().bottomSettingsRangeSelector);
                const timestamp = moment(alert.timestamp).toDate().getTime();

                const now = Date.now();

                const rangeObject = {
                    range: 0,
                    isCustom: false,
                };

                if (timestamp < +(now - rangeObject.range)) {
                    _forEach(rangeSelectorsList, (range) => {
                        if (timestamp > +(now - range.mtime)) {
                            rangeObject.range = range.mtime;
                            return false;
                        }
                    });
                }

                if (!rangeObject.range) {
                    rangeObject.isCustom = true;
                    rangeObject.from = moment(alert.timestamp).utc().subtract(7, "days").unix();
                    rangeObject.to = moment(alert.timestamp).utc().add(7, "days").unix();
                }

                set((state) => {
                    state.rangeSelector = getRangeSelectorObject(rangeObject);
                });
            }
        },
    },
});

const rangeCalculation = (from, to) => {
    const toMoment = moment(Helper.unixToMilliseconds(to));
    const fromMoment = moment(Helper.unixToMilliseconds(from));
    const diffDays = toMoment.diff(fromMoment, "days");

    return microTimeCalculation(diffDays);
};

export const getRangeSelectorObject = (rangeObject) => {
    if (rangeObject.isCustom) {
        return {
            ...rangeObject,
            range: rangeCalculation(rangeObject.from, rangeObject.to),
        };
    }

    return {...rangeObject, from: moment().utc().subtract(rangeObject.range, "millisecond").unix(), to: moment().utc().unix()};
};

const handlerBootRangeSelector = (rangeSelector) => {
    let rangeObject = _cloneDeep(rangeSelector);
    if (typeof rangeObject === "number") {
        return getRangeSelectorObject({isCustom: false, range: rangeObject});
    }

    if (rangeObject.from && rangeObject.to && !rangeObject.isCustom) {
        return getRangeSelectorObject({isCustom: true, ...rangeObject});
    }

    if (rangeObject.range && !rangeObject.isCustom) {
        return getRangeSelectorObject({isCustom: false, ...rangeObject});
    }
    return getRangeSelectorObject(rangeObject);
};

export const chartSelectedRangeStore = create(
    persist(immer(devtools(store)), {
        name: "chartSelectedRangeStore",
        getStorage: () => localStorage,
        partialize: (state) => ({
            rangeSelector: state.rangeSelector,
        }),
        version: 3,
        migrate: (state, version) => {
            if (version === 0) {
                return {...state, rangeSelector: handlerBootRangeSelector(state.rangeSelector)};
            }

            if (version === 1 || version === 2) {
                if (state.rangeSelector.isCustom) {
                    return {...state, rangeSelector: {...state.rangeSelector, range: null}};
                }
                if (!state.rangeSelector.isCustom) {
                    return {...state, rangeSelector: {...state.rangeSelector, from: null, to: null}};
                }
            }
            return state;
        },
    })
);

export const useChartSelectedRangeStore = () => chartSelectedRangeStore((state) => state.rangeSelector);
export const useCurrentChartMarkId = () => chartSelectedRangeStore((state) => state.currentChartMarkId, shallow);
export const useBottomSettingsRangeSelector = () => chartSelectedRangeStore((state) => state.bottomSettingsRangeSelector, shallow);
export const useChartSelectedRangeStoreActions = () => chartSelectedRangeStore((state) => state.actions, shallow);
