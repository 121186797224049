import {useContext, useMemo} from "react";
import Helper from "../../../../../helpers/helper";
import {useLocation} from "react-router";
import {ChartContext} from "../context/context";
import {useChartStateStoreContext, useSelectChartIsFaultFrequencyDetectionMode} from "../store/hooks/useStoreContext";
import {useUserViewChartType} from "../../../../../stores/UserLocalSettingsStore";

export const useSearchParams = () => {
    const location = useLocation();
    return useMemo(() => Helper.getHashParams(), [location]);
};

export const useChartContext = () => useContext(ChartContext);

export const useChartHeight = () => {
    const fullScreenMode = useChartStateStoreContext((state) => state.settings.fullScreenMode);
    const isFaultFrequencyDetectionMode = useSelectChartIsFaultFrequencyDetectionMode();

    const viewChartType = useUserViewChartType();
    const isWaterfallGridView = useChartStateStoreContext((state) => state.settings.isGridView);

    return useMemo(() => {
        if (isFaultFrequencyDetectionMode) {
            return 450;
        }
        if (isWaterfallGridView && fullScreenMode) {
            return 250;
        }
        return fullScreenMode ? window.innerHeight - 250 : viewChartType === "blocks" ? 270 : window.innerHeight * 0.5;
    }, [viewChartType, fullScreenMode, isWaterfallGridView, isFaultFrequencyDetectionMode]);
};
