import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useSelectedAxis} from "./useSelectedAxis";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useCurrentDate} from "./useCurrentDate";
import {usePointData} from "../../../hooks/usePointsData";
import {useInterpolationSyncStoreByPoint} from "../../../store/sync/InterpolationSuncStore";
import {useHighPassFilterSyncStoreByPoint} from "../../../store/sync/HighPassFilterSyncStore";
import {get as _get} from "lodash";
import {useGetFFTReadings, useGetPointReadingParamsByDateTime} from "../../../../../../hooks/api/fftReadings/ReadingsQuery";
import {useMemo} from "react";

export const useChartPeaks = () => {
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const selectedAxis = useSelectedAxis();
    const {equipmentItem} = useEquipmentByParams();
    const currentDate = useCurrentDate();
    const pointData = usePointData(selectedPoint);
    const isInterpolation = useInterpolationSyncStoreByPoint(selectedPoint);
    const highpassFilter = useHighPassFilterSyncStoreByPoint(selectedPoint, pointData);

    const {params: readingsParams} = useGetPointReadingParamsByDateTime(
        equipmentItem?.id,
        pointData?.id,
        selectedAxis,
        currentDate,
        isImpactVue,
        highpassFilter,
        isInterpolation
    );

    const {data: readings, isSuccess} = useGetFFTReadings(chartType, readingsParams);

    const peaks = _get(readings, [0, "peaks"], {});

    const maxPeak = useMemo(() => Math.max(...Object.values(peaks)), [isSuccess]);

    return {peaks, maxPeak};
};
