import React from "react";
import {MenuItem} from "react-contextmenu";
import PropTypes from "prop-types";
import FrequencyConverter from "../../../../../../helpers/frequency-converter";
import {useDeleteReadingModal} from "../../../../../../modals/deleteReadings/hooks/useDeleteReadingModal";
import {useFaultFrequencyFormModal} from "../../../../../../modals/faultFrequencies/hooks/useFaultFrequencyFormModal";
import {useSetSpeedModal} from "../../../../../../modals/installationPoint/hooks/useSetSpeedModal";
import {useChartMarkFftModal} from "../../../../../../modals/chartMarkFft/hooks/useChartMarkFftModal";
import {shallow} from "zustand/shallow";
import auth from "../../../../../../services/auth";
import {useCurrentPointRef} from "../../hooks/useCurrentPointRef";
import {useChartStateStoreContext, useSelectChartIsFaultFrequencyDetectionMode} from "../../store/hooks/useStoreContext";
import {useOverlayChartModal} from "../../../../../../modals/overlayModal/hooks/useOverlayChartModal";
import {useIsOverlayEnabled} from "../../hooks/helpers/useIsOverlayEnabled";
import {useIdleThresholdModal} from "../../../../../../modals/installationPoint/hooks/useIdleThreshold";
import {useSpeedSettings} from "../../hooks/helpers/useSpeedSettings";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useOverlayControls} from "../../hooks/helpers/useOverlayControls";
import {useCurrentDate} from "../../hooks/helpers/useCurrentDate";
import {FREQUENCY_TYPES} from "../../../../../../constants/constants";
import {useIsShowChartMarks, useSharedStoreActions} from "../../../store/sync/sharedStore";

export const ContextMenuFFTContent = (props) => {
    const {showOutlierDialog} = props;
    const currentDate = useCurrentDate();
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const {setIsCurrentSpeedModal, setSpeedModal} = useChartStateStoreContext((state) => state.actions, shallow);
    const currentPoint = useCurrentPointRef().current;
    const {disableOverlay} = useOverlayControls();
    const isOverlayEnabled = useIsOverlayEnabled();
    const {currentSpeed} = useSpeedSettings();
    const {toggleChartMarks} = useSharedStoreActions();
    const isShowChartMarks = useIsShowChartMarks();
    const isFaultFrequencyDetectionMode = useSelectChartIsFaultFrequencyDetectionMode();

    const [showDeleteReadingModal] = useDeleteReadingModal();
    const [showFaultFrequencyFormModal] = useFaultFrequencyFormModal();
    const [showSetSpeedModal] = useSetSpeedModal();
    const [showChartMarkFftModal] = useChartMarkFftModal();
    const [showOverlayModal] = useOverlayChartModal();
    const [handleSetIdleThresholdModal] = useIdleThresholdModal();

    const showCurrentSetSpeedModel = (currentPoint) => {
        setIsCurrentSpeedModal(true);
        showSetSpeedModal(currentPoint);
    };

    const showSpeedSettingsModel = (type) => {
        setIsCurrentSpeedModal(false);
        setSpeedModal({show: true, type});
        showSetSpeedModal();
    };

    return (
        <React.Fragment>
            {auth.userCan("editSelfBearings") && (
                <>
                    <MenuItem onClick={() => showCurrentSetSpeedModel(currentPoint)}>
                        Set running speed at {FrequencyConverter.fromHz(currentPoint?.x).toCpm().numberFormat()} {FREQUENCY_TYPES.CPM}
                    </MenuItem>
                    <MenuItem onClick={() => showSpeedSettingsModel("manually")}>Running speed settings</MenuItem>
                    <MenuItem divider />
                    <MenuItem onClick={handleSetIdleThresholdModal}>Set idle threshold</MenuItem>
                    <MenuItem divider />
                    <MenuItem
                        onClick={showFaultFrequencyFormModal}
                        attributes={{title: currentSpeed ? "" : "Running Speed Is Not Set"}}
                        disabled={!currentSpeed}
                    >
                        Set fault frequency
                    </MenuItem>
                    <MenuItem onClick={showChartMarkFftModal}> Mark this frequency</MenuItem>
                </>
            )}

            {auth.userCan("deleteReading") && (
                <React.Fragment>
                    <MenuItem divider />
                    <MenuItem onClick={showDeleteReadingModal}>Delete current reading</MenuItem>
                </React.Fragment>
            )}
            <MenuItem divider />
            <MenuItem onClick={toggleChartMarks}>{isShowChartMarks ? "Hide" : "Show"} chart marks</MenuItem>
            {!isFaultFrequencyDetectionMode && (
                <MenuItem onClick={() => showOverlayModal()}>{isOverlayEnabled ? "Edit equipment overlay" : "Overlay another reading"}</MenuItem>
            )}
            {isOverlayEnabled && !isFaultFrequencyDetectionMode && <MenuItem onClick={disableOverlay}>Disable overlay</MenuItem>}
            {!isImpactVue && <MenuItem onClick={() => showOutlierDialog(currentDate)}>Mark reading as outlier</MenuItem>}
        </React.Fragment>
    );
};

ContextMenuFFTContent.propTypes = {
    auth: PropTypes.object,
    equipment: PropTypes.object,
    currentPoint: PropTypes.any,
    handleSetModal: PropTypes.func,
    showAddFaultFrequencyModal: PropTypes.func,
    isFft: PropTypes.bool,
    currentSpeed: PropTypes.number,
    showRpmPlotLines: PropTypes.bool,
    showAddChartMarkFftModal: PropTypes.func,
    handleSetIdleThresholdModal: PropTypes.func,
    showOverlayModal: PropTypes.func,
    overlayDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disableOverlay: PropTypes.func,
    showOutlierDialog: PropTypes.func,
};
