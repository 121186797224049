import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helper from "../../../helpers/helper";
import {withGlobalStore} from "../../../stores/GlobalStore";
import ApiNode from "../../../api/node";
import {cloneDeep as _cloneDeep, get as _get} from "lodash";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import PhotosWrapper from "../../../shared/photosWrapper/photosWrapper";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";
import moment from "moment/moment";
import {usePacketLossChartModal} from "../hooks/usePacketLossChartModal";
import SimpleModal from "../../../modals/simple-modal";
import NodeBatteryVoltageChart from "../../../modals/nodeChart/batteryVoltageChart";
import ImageToolbar from "../../../widgets/ImageToolbar";
import {EQUIPMENT_TYPE_NODE} from "../../../constants/constants";
import SignalWarningTooltip from "../../../components/tooltips/SignalWarningTooltip";
import {FirmwareVersion} from "../../../shared/FirmwareVersion/FirmwareVersion";

const Node = (props) => {
    const {node, gateways, user, auth, onEdit = false, clearingData = false, useImageToolbar = false} = props;

    const [modalBatteryVoltage, setModalBatteryVoltage] = useState(false);

    const attachImages = (images) => {
        const {node} = props;

        ApiNode.attachImages(node.serial, {images}).then((response) => {
            if ((response.loaded || {}).images) {
                props.onChange({
                    target: {
                        name: "photos",
                        value: response.loaded.images,
                    },
                });
            }
        });
    };

    const detachImage = (image) => {
        const node = _cloneDeep(props.node);

        ApiNode.detachImage(node.serial, {id: (image || {}).id}).then((response) => {
            props.onChange({
                target: {
                    name: "photos",
                    value: response.images || [],
                },
            });
        });
    };

    const onFlipImage = (imageIndex, flipTurn) => {
        const imageId = _get(node, ["photos", imageIndex, "id"], 0);
        ApiNode.onFlipImage({id: imageId, flipTurn: flipTurn});
    };

    const [handlePacketLoss] = usePacketLossChartModal(node.serial, EQUIPMENT_TYPE_NODE);

    const ports = node.ports;
    const batteryStats = (node.battery_voltage || {}).stats || false;
    const batteryPowerType = node.power_type;
    const batterySetting = Helper.getBatterySetting(batteryPowerType, _get(node, "battery"));
    const hasOnlyDefaultImage = Helper.hasOnlyDefaultImage(_get(node, "photos", []));

    let batteryColor = batterySetting.color;
    let signalColor = node.signal_strength;

    if (!_get(node, "nodeLastSeen") || +moment().diff(moment(_get(node, "nodeLastSeen")), "hours") > 24) {
        batteryColor = "secondary";
        signalColor = node.signal_strength + "-gray";
    }

    let timestampTitle = "";
    for (let i in node.packetTimestamps) {
        if (timestampTitle !== "") {
            timestampTitle += "\n";
        }
        timestampTitle += `Date: ${Helper.dateToUserFormat(node.packetTimestamps[i], user)}`;
    }

    const version = node.code_version;

    return (
        <div>
            <div className="subheader">
                <div className="subheader-title">
                    {onEdit ? "Edit " : ""}Node {node.serial}
                </div>
                <div className="subheader-controls"></div>
            </div>
            <div className="block">
                <div className="block-body gateway-edit">
                    <div className="row">
                        <div className="col-xxl-4 col-lg-5 col-md-5">
                            {clearingData && (
                                <div
                                    className="alert alert-warning"
                                    role="alert"
                                >
                                    <span>Note that the location and all photos will be removed when you change the facility.</span>
                                </div>
                            )}

                            {useImageToolbar ? (
                                <ImageToolbar
                                    title={"Add Node Photo"}
                                    images={node.photos}
                                    onUploadImages={attachImages}
                                    onDelete={detachImage}
                                    withDropzone={onEdit && auth.userCan("editNodes")}
                                    canEdit={!hasOnlyDefaultImage && auth.userCan("editNodes")}
                                    onFlipTurnImage={onFlipImage}
                                />
                            ) : (
                                <PhotosWrapper
                                    photos={node.photos}
                                    onAddPhotos={attachImages}
                                    onDeletePhoto={detachImage}
                                    withPhotoModal={true}
                                    withDropzone={onEdit && auth.userCan("editNodes")}
                                    buttonName={"Add Node Photo"}
                                    displayPhotosAsDefault={hasOnlyDefaultImage}
                                    withoutDeleteModalBtn={typeof props.onChange !== "function"}
                                />
                            )}
                        </div>
                        <div className="col-xxl-1 col-lg-auto col-md-1" />
                        <div className="col-xxl-7 col-lg-6 col-md-6">
                            <table className={"table border-0"}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Serial Number: </strong>
                                        </td>
                                        <td>{node.serial}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Radio Address: </strong>
                                        </td>
                                        <td>{node.radio_address}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Version: </strong>
                                        </td>
                                        <td>
                                            <FirmwareVersion
                                                version={version}
                                                name={node.serial}
                                                isFirmwareExists={!!node.firmwareExist}
                                                description={node.firmwareDescription}
                                                shortDescription={node.firmwareShortDescription}
                                                type="Node"
                                            />
                                        </td>
                                    </tr>
                                    {typeof props.onChange === "function" && (
                                        <tr>
                                            <td>
                                                <strong>Gateway: </strong>
                                            </td>
                                            <td>
                                                {auth.userCan("editNodes") ? (
                                                    <SelectWrapper
                                                        name="gateway_id"
                                                        value={+node.gateway_id}
                                                        onChange={props.onChange}
                                                    >
                                                        {gateways.map((gateway) => (
                                                            <option
                                                                key={gateway.gateway_id}
                                                                value={gateway.gateway_id}
                                                            >
                                                                {gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial}
                                                            </option>
                                                        ))}
                                                    </SelectWrapper>
                                                ) : (
                                                    <React.Fragment>
                                                        {node.gateway
                                                            ? node.gateway.name
                                                                ? node.gateway.name + " (" + node.gateway.serial + ")"
                                                                : node.gateway.serial
                                                            : "---"}
                                                    </React.Fragment>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            <strong>Location: </strong>
                                        </td>
                                        <td>
                                            {typeof props.onChange === "function" ? (
                                                <React.Fragment>
                                                    <input
                                                        type="text"
                                                        value={node.location || ""}
                                                        name="location"
                                                        onChange={props.onChange}
                                                        className="form-control"
                                                        readOnly={!auth.userCan("editNodes")}
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                node.location || "---"
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Asset Tree Branch: </strong>
                                        </td>
                                        <td>
                                            {typeof props.onChange === "function" ? (
                                                <React.Fragment>
                                                    <CollapseLocationSelect
                                                        className={"form-control"}
                                                        selectName={"location_id"}
                                                        value={+_get(node, "location_id", null)}
                                                        onChange={props.onChange}
                                                        needMarkFacility={true}
                                                        emptyOptionLabel={"Select Location"}
                                                        allowedLocationIds={_get(user, "allowedLocationIds", [])}
                                                        disabled={!auth.userCan("editNodes")}
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                _get(node, "location_name") || "---"
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Last Seen: </strong>
                                        </td>
                                        <td>{node.last_seen}</td>
                                    </tr>
                                    {!!node.signal_strength && (
                                        <tr>
                                            <td>
                                                <strong>Signal: </strong>
                                            </td>
                                            <td>
                                                <span
                                                    title={
                                                        Helper.getSignalLabel(node.signal_strength) +
                                                        (node.lastSeen ? `\nFFT: ${Helper.dateToUserFormat(node.lastSeen, user)}` : "")
                                                    }
                                                    className={`signal-lvl position-relative color-${
                                                        node.signal_strength === "default" ? "" : signalColor
                                                    }`}
                                                    style={{marginLeft: 0}}
                                                >
                                                    {!!(signalColor === node.signal_strength + "-gray" && node.signal_strength !== "default") && (
                                                        <img
                                                            title={"Sensor has not been seen in over 24 hours"}
                                                            className={"yellow-time-icon"}
                                                            src={"/assets/pic/icon-time.svg"}
                                                        />
                                                    )}
                                                    {node.signal_strength === "default" ? (
                                                        "N/A"
                                                    ) : (
                                                        <img src={`/assets/pic/icon-signal-level-${signalColor}.svg`} />
                                                    )}
                                                </span>
                                                {node.packetTimestamps.length > 0 && (
                                                    <span
                                                        className="btn btn-elevate btn-circle btn-icon"
                                                        style={{color: "#777", marginLeft: "10px"}}
                                                        title={timestampTitle}
                                                    >
                                                        <i className="fa fa-info-circle" />
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    {batteryStats !== false && (
                                        <tr>
                                            <td>
                                                <strong>Battery: </strong>
                                            </td>
                                            <td>
                                                <span
                                                    className={`position-relative color-${batteryColor}`}
                                                    style={{marginLeft: 0}}
                                                >
                                                    {batteryColor === "secondary" && (
                                                        <img
                                                            title={"Sensor has not been seen in over 24 hours"}
                                                            className={"yellow-time-icon"}
                                                            src={"/assets/pic/icon-time.svg"}
                                                        />
                                                    )}
                                                    {batteryColor === "" ? (
                                                        "N/A"
                                                    ) : batteryColor === "warning" ? (
                                                        <i className="fa fa-battery-half" />
                                                    ) : (
                                                        <i className="fa fa-battery-full" />
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            <strong>Packet Loss Chart:</strong>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-success btn-elevate btn-circle btn-icon"
                                                title={"Show Packet Loss Chart"}
                                                onClick={handlePacketLoss}
                                            >
                                                <i className="flaticon2-chart" />
                                            </button>
                                            <SignalWarningTooltip
                                                packetLossPercent={_get(node, "packetLossPercent")}
                                                tooltipText={"FFT transmission may be impacted by poor connectivity to the device."}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Battery Voltage Chart:</strong>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-success btn-elevate btn-circle btn-icon"
                                                title={"Show Battery Voltage Chart"}
                                                onClick={() => {
                                                    setModalBatteryVoltage(true);
                                                }}
                                            >
                                                <i className="flaticon2-chart" />
                                            </button>
                                            <SimpleModal
                                                showModal={modalBatteryVoltage}
                                                title={"Battery Voltage Chart"}
                                                withoutSubmit={true}
                                                onClose={() => {
                                                    setModalBatteryVoltage(false);
                                                }}
                                                size={"lg"}
                                            >
                                                <NodeBatteryVoltageChart node={node} />
                                            </SimpleModal>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th colSpan={6}>
                                    <h5>Tethered Sensors</h5>
                                </th>
                            </tr>
                            <tr>
                                <th>Port</th>
                                <th>Sensor ID</th>
                                <th>Installed On</th>
                                <th>Equipment</th>
                                <th>Version</th>
                                <th>Type</th>
                                <th>Last Seen</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {ports.length > 0 ? (
                                ports.map((port) =>
                                    _get(port, "tachometer") ? (
                                        <TachometerRow
                                            key={`sensor-${node.serial}-${port.position}`}
                                            position={port.position}
                                            sensor={port.sensor}
                                            tachometer={port.tachometer}
                                            equipmentName={port?.sensor?.installationPoint?.equipmentName}
                                        />
                                    ) : (
                                        <SensorRow
                                            key={`sensor-${node.serial}-${port.position}`}
                                            position={port.position}
                                            sensor={port.sensor}
                                            equipmentName={port?.sensor?.installationPoint?.equipmentName}
                                        />
                                    )
                                )
                            ) : (
                                <tr>
                                    <td
                                        className="text-info text-center"
                                        colSpan={7}
                                    >
                                        No attached sensors
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

Node.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    node: PropTypes.object,
    gateways: PropTypes.array,
    onChange: PropTypes.func,
    onShowBatteryVoltageModal: PropTypes.func,
    auth: PropTypes.object,
    onEdit: PropTypes.bool,
    clearingData: PropTypes.bool,
    useImageToolbar: PropTypes.bool,
};

const SensorRow = ({position, sensor = {}, equipmentName}) => {
    const installationPoint = sensor.installationPoint || {};

    let portColor = "badge badge-secondary";
    let rowColor = "";

    const rows = [];

    if (sensor && sensor.sensor_id) {
        let hexValue = parseInt(sensor.sensor_id);
        if (parseInt(sensor.hardware_id)) {
            hexValue = parseInt(sensor.hardware_id).toString(16).toUpperCase();
        }
        rows.push(<td key={`sensor-id-${sensor.sensor_id}`}>{hexValue}</td>);
        if (installationPoint.id) {
            rowColor = "";
            portColor = "badge badge-md badge-success badge-circle";
            rows.push(<td key={`sensor-badge-${sensor.sensor_id}`}>{installationPoint.name}</td>);
        } else {
            rowColor = "";
            portColor = "badge badge-md badge-success badge-circle";
            rows.push(
                <td key={`unassigned-${position}`}>
                    <i>No installation point set</i>
                </td>
            );
        }
        rows.push(
            <td key={`equipment-${equipmentName}`}>
                <i>{equipmentName || "No equipment set"}</i>
            </td>
        );
        rows.push(<td key={`sensor-version-${sensor.sensor_id}`}>{sensor.version}</td>);
        rows.push(<td key={`sensor-version-type-${sensor.sensor_id}`}>{sensor.version_type || <i>Not set</i>}</td>);
        rows.push(<td key={`sensor-last-seen-${sensor.sensor_id}`}>{sensor.last_seen}</td>);
        rows.push(
            <td key={`sensor-link-${sensor.sensor_id}`}>
                {!!installationPoint.id && (
                    <Link
                        to={`/chart/${installationPoint.equipment_id}#sensors=${installationPoint.id}`}
                        className="btn btn-success btn-elevate btn-circle btn-icon"
                        style={{marginRight: 10}}
                        title={"View Sensor"}
                    >
                        <i className="flaticon2-chart" />
                    </Link>
                )}
            </td>
        );
    } else {
        return (
            <tr style={{backgroundColor: "rgb(228, 228, 228)"}}>
                <td>
                    <span
                        className={portColor}
                        style={{borderRadius: "100%"}}
                    >
                        {position}
                    </span>
                </td>
                <td
                    colSpan={8}
                    className="text-center"
                />
            </tr>
        );
    }

    return (
        <tr className={rowColor}>
            <td>
                <span className={portColor}>{position}</span>
            </td>
            {rows}
        </tr>
    );
};

SensorRow.propTypes = {
    position: PropTypes.number,
    sensor: PropTypes.object,
    equipmentName: PropTypes.string,
};

const TachometerRow = ({position, sensor, tachometer, equipmentName}) => {
    const portColor = "badge badge-md badge-primary";
    const tachometerPoints = _get(tachometer, "tachometerPoints");

    return (
        <tr style={{borderRadius: "100%"}}>
            <td>
                <span className={portColor}>{position}</span>
            </td>
            <td />
            <td>{tachometer.location_info}</td>
            <td>{equipmentName || <i>No equipment set</i>}</td>
            <td colSpan={2}>Tachometer</td>
            <td>{sensor.last_seen}</td>
            <td>{!!tachometerPoints && <TachometerPort tachometerPoints={tachometerPoints} />}</td>
        </tr>
    );
};

TachometerRow.propTypes = {
    position: PropTypes.number,
    tachometer: PropTypes.object,
    sensor: PropTypes.object,
    equipmentName: PropTypes.string,
};

const TachometerPort = ({tachometerPoints}) => {
    if (!tachometerPoints.length) {
        return "";
    } else if (tachometerPoints.length === 1) {
        return (
            <Link
                to={`/chart/${tachometerPoints[0].equipment_id}`}
                className="btn btn-primary btn-elevate btn-circle btn-icon badge badge-primary"
                style={{marginRight: 10, fontSize: "1rem"}}
                title={"View Equipment"}
            >
                <i className="flaticon2-chart" />
            </Link>
        );
    } else {
        return (
            <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle
                    tag={"span"}
                    className="btn btn-elevate btn-circle btn-icon badge badge-primary"
                    style={{marginRight: 10, fontSize: "1rem"}}
                    title={"View Equipment"}
                >
                    <i className="flaticon2-chart" />
                </DropdownToggle>
                <DropdownMenu right={true}>
                    {tachometerPoints.map((tachometerPoint, index) => (
                        <DropdownItem
                            key={index}
                            tag={Link}
                            to={`/chart/${tachometerPoint.equipment_id}`}
                        >
                            {tachometerPoint.equipment.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
};

TachometerPort.propTypes = {
    port: PropTypes.object,
};

export default withGlobalStore(Node);
