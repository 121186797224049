import React from "react";
import PropTypes from "prop-types";
import {useChartStateStoreContext, useSelectChartIsFaultFrequencyDetectionMode} from "../../../../../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useEquipmentByParams} from "../../../../../../../../../../hooks/api/equipment/useEquipmentQuery";
import {usePointData} from "../../../../../../../hooks/usePointsData";

const ChartHeaderWrapper = ({children}) => {
    const {fullScreenMode, seriesList, selectedPoint} = useChartStateStoreContext((state) => state.settings, shallow);
    const isFaultFrequencyDetectionMode = useSelectChartIsFaultFrequencyDetectionMode();

    const {equipmentItem} = useEquipmentByParams();
    const pointData = usePointData(selectedPoint);

    return (
        <div className={`chart-block-header ${isFaultFrequencyDetectionMode && "chart-block-header_in-detection"}`}>
            <div className="chart-block-title">
                {fullScreenMode && <div className="chart-title-equipmentItem">{equipmentItem.name}</div>}
                <div className="chart-title-text">
                    {seriesList.title}
                    <span> - {pointData.name}</span>
                </div>
            </div>
            <div className="chart-block-actions position-relative">{children}</div>
        </div>
    );
};

ChartHeaderWrapper.propTypes = {
    children: PropTypes.node,
};

export default ChartHeaderWrapper;
