import React from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {get as _get, includes as _includes} from "lodash";
import InputRange from "react-input-range";

import "react-input-range/lib/css/index.css";
import SelectWrapper from "../../../helpers/select-wrapper";

const FMaxSelect = ({value, sensorParameters, name, onChange, disabled = false, errorMsg, isHFDvue = false, axisKey = null}) => {
    const fmax = axisKey === null ? sensorParameters?.fmax || [] : sensorParameters.axisMaskOnDemand[axisKey].fmax || [];
    const maxFMax = _get(sensorParameters, "maxFMax");
    let values = fmax.length ? fmax : [];

    if (isHFDvue) {
        values = _get(sensorParameters, "impactvue_fmax", []) || [];
    }

    if (disabled) {
        return (
            <>
                <input
                    className={"form-control request-form-control" + (errorMsg ? " is-invalid" : "")}
                    type="number"
                    name={name || "f_max"}
                    value={value}
                    disabled={true}
                />
                {!!(+_get(sensorParameters, "bandwidth") && +value > +_get(sensorParameters, "bandwidth")) && (
                    <>
                        {!fmax.length ? (
                            <div className={"text-primary mb-2"}>If you adjust the Fmax, aliasing may occur.</div>
                        ) : (
                            <div className={"text-primary mb-2"}>This Fmax exceeds the flat response bandwidth.</div>
                        )}
                    </>
                )}
            </>
        );
    }

    if (values.length && !_includes(values, +value)) {
        onChange({
            target: {
                name: name || "f_max",
                value: values[0],
            },
        });
    }

    if (!fmax.length && !isHFDvue) {
        return (
            <>
                <input
                    className={"form-control request-form-control" + (errorMsg ? " is-invalid" : "")}
                    type="number"
                    name={name || "f_max"}
                    value={value || _get(sensorParameters, "defaultFMax")}
                    step={1}
                    min={1}
                    max={maxFMax}
                    onChange={(e) => {
                        if (e.target.value <= maxFMax && e.target.value >= 1) {
                            onChange(e);
                        }
                    }}
                />
                <InputRange
                    maxValue={maxFMax}
                    minValue={1}
                    value={value || _get(sensorParameters, "defaultFMax")}
                    onChange={(value) => {
                        if (value <= maxFMax && value >= 1) {
                            onChange({
                                target: {
                                    name: name || "f_max",
                                    value,
                                },
                            });
                        }
                    }}
                />
                {!!(+_get(sensorParameters, "bandwidth") && +value > +_get(sensorParameters, "bandwidth")) && (
                    <div className={"text-primary mb-2 mt-4"}>If you adjust the Fmax, aliasing may occur.</div>
                )}
            </>
        );
    }

    return (
        <>
            <SelectWrapper
                // ref={inputRef}
                className={errorMsg ? " is-invalid" : ""}
                name={name || "f_max"}
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                <option
                    value=""
                    disabled
                >
                    FMax
                </option>
                {values.map((fMaxValue) => (
                    <option
                        key={`fmax-value-${fMaxValue}`}
                        value={fMaxValue}
                    >
                        {fMaxValue}
                    </option>
                ))}
            </SelectWrapper>
            {!!(+_get(sensorParameters, "bandwidth") && +value > +_get(sensorParameters, "bandwidth")) && (
                <div className={"text-primary mb-2"}>This Fmax exceeds the flat response bandwidth.</div>
            )}
        </>
    );
};

FMaxSelect.propTypes = {
    disabled: PropTypes.bool,
    isHFDvue: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    maxValue: PropTypes.number,
    versionType: PropTypes.number,
    sensorParameters: PropTypes.object,
    errorMsg: PropTypes.string,
    user: PropTypes.object,
    needUpdateOnNotValidValue: PropTypes.bool,
    axisKey: PropTypes.string,
};

export default withGlobalStore(FMaxSelect);
