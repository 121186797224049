import React from "react";
import {CHART_TYPE} from "../../../../hooks/useChartAbstractFactory";
import FrequencyUnitButton from "../../../../components/buttons/frequencyUnitButton";
import CursorButton from "../../../../components/buttons/cursorButton";
import "./chartFooter.scss";
import {useChartStateStoreContext, useSelectChartIsFaultFrequencyDetectionMode} from "../../../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import MaxHarmonicButton from "../../../../components/buttons/maxHarmonicButton";
import FrequencyRange3d from "../../../../components/buttons/FrequencyRange3d";

function ChartFooter() {
    const {chartTypeName} = useChartStateStoreContext((state) => state.factory, shallow);
    const isFaultFrequencyDetectionMode = useSelectChartIsFaultFrequencyDetectionMode();

    return (
        <>
            <div className="chart-footer">
                {(chartTypeName === CHART_TYPE.FFT || chartTypeName === CHART_TYPE.FFT_IMPACT) && <FrequencyUnitButton />}
                {!isFaultFrequencyDetectionMode && (
                    <>
                        <CursorButton />
                        <MaxHarmonicButton />
                        <FrequencyRange3d />
                    </>
                )}
            </div>
        </>
    );
}

export default ChartFooter;
